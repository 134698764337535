.digitalLibraryTabPanel a {
  display: block;
}

/* oj tab bar focus visivility */
#contLearning .oj-focus-highlight,
#contLearningEls .oj-focus-highlight,
#hnavlist .oj-focus-highlight,
.navListTab .oj-focus-highlight
 {
  --oj-core-focus-border-color: #201E1C !important;
  outline-width: 2px !important;
  outline-offset: -2px !important;
}
