.old-story-header-section-container .story-header-section-row {
  display: flex;
  flex-wrap: nowrap;
}

.old-story-header-section-container .story-header-section-right-column {
  float: right;
  text-align: right;
  display: flex;
  flex-wrap: nowrap;
  margin-left: 8em;
}

.old-story-header-section-container .story-header-section-right-column oj-button {
  display: inline-block;
}

.old-story-header-section-container .community-dialog-wrapper {
  display: inline-block;
}

.old-story-header-section-container .story-header-section-row .story-header-section-left-column div.oj-header-description {
  margin-top: 1rem;
}

@media screen and (max-width:1024px) {
  .old-story-header-section-container .story-header-section-row {
    flex-wrap: wrap;
  }

  .old-story-header-section-container .story-header-section-right-column {
    flex-wrap: wrap;
    margin-left: 0em;
    margin-top: 10px;
  }

}

@media screen and (max-width:786px) {
  .old-story-header-section-container .story-header-section-right-column {
    margin-top: 0px;
    gap: 8px;
  }
}
