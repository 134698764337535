oj-sp-object-card .els-event-card {
    width: 320px;
    height: 470px;
    margin: 0;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 30%) !important;
    background-color: var(--oj-panel-bg-color);
    border-radius: var(--oj-panel-border-radius);
    overflow: hidden;
}

.els-event-card a {
    color: var(--oj-link-text-color);
}

.els-event-card a:focus{
    outline: dotted 2px black;
    outline-offset: -1px;
}

.els-event-card hr {
    margin-top: 24px;
    margin-bottom: 24px;
}

.els-event-card-footer {
    height: 84px;
    width: 100%;
    background-color: rgb(var(--oj-palette-neutral-rgb-20));
}

div[id^="eventCourseBtn"] .oj-button-button {
    background-color: var(--oj-panel-bg-color) !important;
}

.els-card-secondary-text {
    color: #312D2A;
}

.els-card-secondary-text div {
    margin-top: 6px;
}

.els-link-btn-wrapper {
    text-decoration: none;
}

.els-timer {
    min-height: 96px;
}

.els-card-title {
    min-height: 56px;
}

.els-card-title:focus {
    outline: dotted 2px black;
    outline-offset: -1px;
}