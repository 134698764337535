.oj-actioncard {
    --oj-action-card-scale-hover: 1.02
}

.tile-wrapper {
    width: 318px;
    height: 400px;
    margin-bottom: 40px;
}

.tile-wrapper.expanded {
    z-index: 100;
}

.tile-wrapper.expanded .tile-container {
    box-shadow: rgba(155, 155, 155, 0.62) 11px 10px 7px;
}

.tile-container {
    pointer-events: auto;
    border-radius: 0.5em;
    filter: none;
    line-height: 40px;
    overflow-wrap: break-word;
    cursor: pointer;
    border-color: rgb(160, 160, 160);
    text-align: center;
    color: rgb(255, 255, 255);
    border-style: none;
    font-size: 18px;
    font-weight: bold;
    border-width: 0px;
    overflow: hidden;
  user-select: none;
}

oj-sp-object-card {
    display: block;
    margin: 0.5rem;
}

#tilebox-oj-badge-section {
    width: '100%'
}

#tilebox-oj-badge-section > .oj-badge {
    padding: 0 .5rem;
    display: inline-flex;
    box-sizing: border-box;
    border-radius: .375rem;
    height: 1.5rem;
    line-height: 1.5rem;
    min-width: 1.5rem;
    font-size: 0.6875rem;
    font-weight: 700;
    font-stretch: condensed;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

}

.oj-conveyorbelt oj-sp-object-card,
    oj-sp-object-card.oj-conveyorbelt {
    width: 308px;
}

oj-sp-object-card:not(.oj-complete) {
    visibility: hidden;
}

oj-sp-object-card:not(.els-card-object) a:link, oj-sp-object-card:not(.els-card-object) a:visited, oj-sp-object-card:not(.els-card-object) a:active {
    text-decoration: none;
    color: inherit;
}

oj-sp-object-card .oj-sp-object-card-parent-container, .skeleton,
oj-sp-image-card .oj-actioncard {
    width:20rem;
    margin: 0;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 30%) !important;
    height: 23rem;
}
oj-action-card[id^="large_"] {
    height: 100%;
}

oj-sp-object-card .oj-sp-object-card-header {
   height: 3.25rem;
}

oj-sp-object-card .oj-sp-object-card-body {
    height: 6rem;
}

oj-sp-object-card .oj-sp-object-card-image {
    max-width: 96px;
    max-height: 96px;
}

oj-sp-object-card .oj-sp-object-card-image-container {
    position: relative;
    border-radius: 6px;
    min-width: 96px;
    max-width: 96px;
    height: 96px;
    overflow: hidden;
}

oj-sp-object-card .oj-sp-object-card-image-scrim {
    position: absolute;
    background-color: rgb(var(--oj-palette-neutral-rgb-200));
    opacity: 0.05;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

oj-sp-object-card .oj-sp-object-card-flex-shrink {
    min-width: 0;
}

oj-sp-object-card .oj-sp-object-card-text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.oj-sp-card-footer-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
}

oj-sp-object-card .oj-sp-object-card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.oj-badge-type{background-color: #846A92;}

.carouselsection{clear: both;}

.skeleton{
    height:360px;
    position: relative;
    overflow: hidden;
}

.skeleton::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 2s infinite;
            animation: shimmer 2s infinite;
    content: "";
}

@-webkit-keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.oj-filmstrip:focus {
    outline-color: black;
    outline-width: 2px;
}

.oj-filmstrip-arrow, .oj-filmstrip-arrow:hover{background: #000; border-radius: 100%;opacity: 0.7;}

.oj-filmstrip-arrow.oj-default .oj-filmstrip-arrow-icon{color:#fff}

.oj-filmstrip-arrow:hover{opacity: 1;}

.oj-popup-tail.oj-popup-tail-simple.oj-center.oj-bottom:after,
.oj-popup-tail.oj-popup-tail-simple.oj-right.oj-bottom::after {
    border-top: calc(var(--oj-popup-tail-height) - 1px) solid rgb(49, 45, 42)
}

.oj-popup-tail.oj-popup-tail-simple.oj-center.oj-bottom:before,
.oj-popup-tail.oj-popup-tail-simple.oj-right.oj-bottom::before{
    border-top: var(--oj-popup-tail-height) solid rgb(49, 45, 42);}

.tooltipMinWidth{max-width:15em;}

.tooltipMinWidthFav{width:fit-content}
.oj-drawer-popup-surrogate .oj-drawer-end.editAbout-drawer{width:100%; max-width:100vh;}

.editAbout-drawer-header {display: flex;justify-content: space-between;align-items: center;}

.editAbout-drawer-header h6{margin: 0;}

.oj-text-field.oj-select-multi .oj-text-field-container{padding: 1em;}

.oj-listbox-results .oj-listbox-result-label{cursor: auto;}

.optGroup .oj-listbox-result-label{cursor: pointer;}

.editAbout-drawer input::selection,.editAbout-drawer textarea::selection{color: #fff;background:#1890ff !important;}

.oj-select-selected-choice{border-color: #665f5b;
    font-size: 0.95em;
    margin: 5px 5px 5px 0;
    border-radius: 15px;
    background: #ededed;}.percentage-width{width:50%}.editAbout-drawer .percentage-width{width:98%}
    @media screen and (max-width:1200px) {
    .percentage-width{width: 100%;}
}

.title-tilebox-component:focus, .title-tilebox-component:active {
    outline: dotted 2px black;
    outline-offset: -1px;
}

.oj-tilebox-subtitle {
  font-size: 10px;
}

.lab-container{
    border-top: 9px solid #33553c;
}
