* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lp-page-container {
  padding: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 30px;
  overflow: hidden;
}

#lp-page .oj-button-icon {
  margin: 0 !important;
  font-size: 24px !important;
  padding: 0 5px;
}

#lp-page .lp-page-container ul li {
  cursor: default !important;
}

.lp-page-container-title {
  cursor: pointer;
}

.lp-goal-section {
  background: #FBF9F8;
}

.lp-page-total-progress-circle {
  text-align: center;
}

.lp-page-complete-txt span {
  margin-top: 5px;
}

.lp-page-complete-txt span:first-child {
  margin-top: 0px;
}

#lp-page .oj-listview-item {
  border-bottom: none !important;
}

.lp-page-component {
  flex-wrap: nowrap !important;
}

.lp-page-component .lp-page-component-block {
  min-height: 87px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lp-page-component-box {
  background: #FBF9F8;
  border-radius: 6px;
  width: 97%;
  padding: 10px 20px;
  margin-left: 40px;
  border: 1px solid rgba(22, 21, 19, 0.1);
  border-left: 5px solid rgb(255 208 155);
}
.lp-page-component-box.lp-page-comp-ilp-exam,
.lp-page-component-box.lp-page-comp-ass-cap,
.lp-page-component-box.lp-page-comp-ass-exam,
.lp-page-component-box.lp-page-comp-ass-quiz,
.lp-page-component-box.lp-page-comp-cert,
.lp-page-component-box.lp-page-comp-exam-proc,
.lp-page-component-box.lp-page-comp-exam-unproc {
  border-left: 5px solid rgb(107 101 92);
}

ul.oj-listview-element.oj-component-initnode li {
  background: #FFFFFF;
}

.lp-page-component:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 26px;
  border-left: 1px solid #e0e0df !important;
}

ul.oj-listview-element.oj-component-initnode li:first-child .lp-page-component:before {
  top: 42%;
}

ul.oj-listview-element.oj-component-initnode li:last-child .lp-page-component:before {
  bottom: 52%;
}

.lp-page-with-no-badge ul.oj-listview-element.oj-component-initnode li:last-child .lp-page-component:before {
  border-left: none;
}

.lp-page-component:after {
  content: '\f3c7';
  display: block;
  position: absolute;
  top: 42%;
  bottom: 0;
  left: 16px;
  font-family: ojuxIconFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  speak: none;
  font-size: 20px;
  color: rgba(49, 45, 42, 0.25);
  z-index: 99;
  background: #FBF9F8;
  height: 20px;
}

.lp-page-component.lp-page-container-in-progress:after {
  content: '\f658';
  color: rgb(34, 126, 158);
}

.lp-page-component.lp-page-container-completed:after {
  content: '\f3c6';
  color: rgb(34, 126, 158);
}

.lp-page-course-duration {
  margin-top: 0.5em;
}

.lp-page-course-progress-txt {
  text-align: center;
}

.lp-page-component-fav {
  vertical-align: -17px;
  padding: 15px;
}

.lp-page-badge-section {
  position: relative;
  background: #fff !important;
}

.lp-page-badge-section h4 {
  margin: 0 !important;
  margin-left: 35px !important;
}

.lp-page-badge-section:before {
  content: "";
  display: block;
  position: absolute;
  top: -48%;
  bottom: 52%;
  left: 66px;
  border-left: 1px solid #e0e0df;
}

.lp-page-badge-section:after {
  content: '\f3c7';
  display: block;
  position: absolute;
  top: 42%;
  bottom: 0;
  left: 56px;
  font-family: ojuxIconFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  speak: none;
  font-size: 20px;
  color: rgba(49, 45, 42, 0.25);
  z-index: 100;
  background: #FFFFFF;
  height: 20px;
}

.goal-title {
  margin: 1em 0 0 0;
}

.oj-text-right {
  text-align: right;
}

.oj-listitemlayout-grid .oj-badge {
  margin: 0 0.5em 0 0;
}

.oj-progress-bar-value {
  background-color: #227E9E !important;
}

@media only screen and (max-width: 600px) {
  .goal-title span {
    display: block;
    font-size: 0.90em !important
  }

  .progress-bar {
    position: absolute;
    bottom: -1.5em;
    left: 0;
    width: 100% !important;
    max-width: 100% !important;
  }

  .lp-page-component-box {
    margin: 0;
  }

  .lp-page-component:before {
    left: 7px
  }

  .lp-page-component:after {
    left: -2px
  }

  .lp-page-badge-section:after {
    left: 10px
  }

  .lp-page-badge-section:before {
    left: 19px;
  }

  .oj-position-relative {
    position: relative;
    margin-bottom: 1em;
  }

  .lp-page-component-box {
    padding: 0.5em;
  }

  .oj-sp-list-item-template-md-badge-fixed {
    margin-bottom: 0.5em;
  }

  .oj-listitemlayout-grid .oj-badge {
    margin: 0.5em 0.5em 0.5em 0;
  }

  .lp-page-component-box {
    width: 100%;
    margin-left: 0.5em;
  }

  #learningPathDescription {
    flex-wrap: wrap !important;
  }
}

.oj-sp-theme-global-mode-mixed.lp-page {
  background: transparent !important;
}

#lp-page button.oj-button-button:focus-visible {
  outline: 1px solid #201E1C;
  outline-offset: 1px;
}

#learningPathDescriptionHtml {
  color: #ffffff;
}

#learningPathDescriptionHtml a {
  text-decoration: underline;
}

#learningPathDescriptionHtml h1 {
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
}

.badge-text {
  display: inline-block;
  color: #333333;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  padding: 4px 0px;
}

@media only screen and (max-width: 1392px) {
  #learningPathDescriptionVideo iframe {
    height: 180px;
    width: 320px;
  }
}

@media only screen and (max-width: 600px) {
  #learningPathDescriptionVideo iframe {
    height: 152px;
    width: 270px;
  }
}

#unauthorizedModalDialog button.oj-button-button:focus-visible {
  outline: 2px dotted #201E1C;
  outline-offset: 1px;
}