.search-box {
    margin:8px 10px 10px 0;
    border-style: none;
    border-width: 0px;
    padding:5px 19px;
    background-color:#47423E;
    border-radius: 45px;
    border-color: rgba(246, 245, 243, 0.09);
    line-height: 39px;
    font-weight: normal;
    color: #fff;
    cursor: pointer;
    overflow: visible;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(71,66,62,255);
}
@media screen and (prefers-contrast: more) {
  .search-box {
    border: 1px solid white;
  }
}
@media (inverted-colors) {
  .search-box {
    border: 1px solid white;
  }
}
.search-box .oj-text-field .oj-text-field-container{background: none;border: none;color: inherit;}
.search-box  oj-focus .oj-default .oj-clickable-icon{
  color: var(--oj-private-icon-color-default);
}
.search-box.ojis-focus{background-color: #fff;}
.search-box:hover .oj-inputtext-clear-icon{color:#fff}
.search-box.ojis-focus:hover .oj-inputtext-clear-icon{color:#333}
.oracle-logo{position: relative;top: -5px;display: flex; align-items: center}
.search-box input{
    font-size: 18px;
    font-weight: normal;
    color: var(--fontColor);
}
.search-box input::placeholder, #mainsearch span.oj-sp-filter-chip-applied-label,
#mainsearch .oj-ux-ico-close, .oj-inputsearch-search-icon {color: #fff;opacity: 1;}
.search-box.ojis-focus .oj-inputsearch-search-icon{color:#333}
#mainsearch|input::selection {
  color: #fff;
  background: #1890ff;
}

#mainsearch.oj-text-field.oj-focus:not(.oj-read-only):not(.oj-invalid):not(.oj-warning) .oj-text-field-container {
    border-color: white !important;
    box-shadow: none !important;
}

.search-box input:-webkit-autofill {
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
}
.search-box input {
  color: #fff;
  -webkit-text-fill-color: #fff;
}
.search-box.ojis-focus input {
  color: rgb(22, 21, 19);
  -webkit-text-fill-color: rgb(22, 21, 19);
}
.search-box.ojis-focus input:placeholder-shown{
  color: #999;
  -webkit-text-fill-color: #161513B2;
}

.search-box .search-icon{    
  font-size: 22px;
  color: var(--fontColor);
  margin-right: 0;
  vertical-align: middle;
  cursor: default;
}

.header-menu .ml-menu{
    margin-right: 20px;
    margin-top: 5px;
    width: 100%;
}

.header-menu .ml-m-item {
    cursor: pointer; 
    width: max-content;
    color: var(--fontColor) !important;
    padding-bottom: 5px;
}

.header-menu .ml-m-item:hover,.header-menu .ml-m-item.active {
    border-bottom: 3px solid;
}

.header-menu  .ml-m-item.accounts {
    background-color: rgb(86, 80, 76);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: var(--fontColor);
    border-color: rgba(246, 245, 243, 0.09);
    border-radius: 40px;
    padding: 8px 12px 8px 12px;    
    
}

.header-menu .ml-m-item span.anticon {
    font-size: 20px;
}


.header-menu .ml-menu span {
    color: var(--fontColor);
}


.head-dashboard{
    height: 200px;    
    background-color:  rgba(47, 43, 40, 0.3);
}

.head-dashboard h4, .head-dashboard h5{
    color: var(--fontColor);
}

.head-dashboard .profile-section small a {
    color: navajowhite !important;
}

.head-dashboard .profile-section{
    margin-left: 30px;
    margin-top: 30px;
    color: var(--fontColor);
}

.head-dashboard .summary-section, .head-dashboard .notification-section{
    border-left: 3px solid rgb(212, 187, 120);
    margin-top: 60px;
    padding-top: 10px;
    padding-left: 20px;
    height: 80px;
    color: var(--fontColor);
}

.head-dashboard .summary-section span{
    font-weight: bolder;
}

.head-dashboard .notification-section span{
    font-weight: bolder;
    color:  var(--secFontColor) ;
    cursor: pointer;
}

.se-displayIcons i{
    font-size: large;
}
oj-sp-header-general-overview .oj-sp-header-general-overview-grid-3{
    display: block;
}

oj-sp-header-welcome-banner:not(.oj-complete) {
    visibility: hidden;
  }
  
  oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-background-auto):not(.oj-sp-header-welcome-banner-sm) {
    display: block;
    margin-bottom: 2rem; /* Offset of 32 px when the background is not auto */
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-container {
    position: relative;
    width: 100%;
    min-height: 19rem;
    background-color: var(--oj-sp-header-welcome-banner-background-color) !important;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-image-panel {
    position: relative;
    width: 45%;
    background-color: var(--oj-sp-header-welcome-banner-background-color);
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-full-bleed-image-panel {
    width: 100%;
    background-color: var(--oj-sp-header-welcome-banner-background-color);
  }
  
  oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-texture {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-texture::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    width: 40rem;
    height: 22rem;
    background-image: url(https://static.oracle.com/cdn/fnd/gallery/2401.0.1/images/illust-welcome-banner-bg-01.png);
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .oj-navigation-guest-account {
    display: flex;
    flex-direction: column;
  }
  
  oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-gradient {
    -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 10rem, black 100%);
    mask-image: linear-gradient(90deg, transparent 0%, black 10rem, black 100%);
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-md .oj-sp-header-welcome-banner-gradient {
    -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 3.125rem, black 100%);
    mask-image: linear-gradient(90deg, transparent 0%, black 3.125rem, black 100%);
  }
  
  html[dir=rtl] oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-gradient {
    -webkit-mask-image: linear-gradient(270deg, transparent 0%, black 10rem, black 100%);
    mask-image: linear-gradient(270deg, transparent 0%, black 10rem, black 100%);
  }
  
  html[dir=rtl] oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-md .oj-sp-header-welcome-banner-gradient {
    -webkit-mask-image: linear-gradient(270deg, transparent 0%, black 3.125rem, black 100%);
    mask-image: linear-gradient(270deg, transparent 0%, black 3.125rem, black 100%);
  }
  
  oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-image {
    position: absolute;
    bottom: 0; /* -32 px */
    right: 0;
    width: auto;
    height: 100%;
    min-height: 19rem; /* 228 px */
    object-fit: cover;
  }

  @media screen and (max-width: 1324px) {
    oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-image {
      display: none;
    }
  }

  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-full-bleed-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-scrim-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    opacity: 1;
    pointer-events: none;
    mix-blend-mode: var(--oj-sp-header-scrim-opacity);
    background-image: var(--oj-sp-header-welcome-banner-scrim-url);
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-image {
    position: relative;
    height: 100%;
    left: -50%;
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-background-auto:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-image {
    top: 0;
    bottom: initial;
    height: 100%;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-main-panel {
    width: 55%;
    z-index: 1;
  }

  @media screen and (max-width: 1324px) {
    oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-main-panel {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    .display-block {
      display: block !important;
    }

    .active-site {
      font-weight: 200;
      font-size: 0.9rem;
      margin-bottom: -15px;
      display: block;
    }
  }

  .switch-site {
    margin-left: 2rem;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-title-panel {
    width: 100%;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-search-panel {
    max-width: 37.5rem;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-toolbar-container {
    height: 2.75rem;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-toolbar {
    padding-bottom: 0;
    padding-top: 0; /* overwrite top and bottom padding in oj-toolbar */
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-sm-toolbar {
    display: inline-block;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-no-wrap {
    white-space: nowrap;
  }
  
  /* Medium Size */
  
  /* Medium Size - Image Panel */
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-md .oj-sp-header-welcome-banner-image-panel {
    width: 45%;
    background-color: var(--oj-sp-header-welcome-banner-background-color);
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-md .oj-sp-header-welcome-banner-full-bleed-image-panel {
    width: 100%;
    background-color: var(--oj-sp-header-welcome-banner-background-color);
  }
  
  /* Small Size */
  
  /* Small Size - Image Panel */
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-image-panel {
    width: 100%;
    background-color: var(--oj-sp-header-welcome-banner-background-color);
  }
  
  /* Small Size - Image */
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-image-container {
    height: 9.25rem; /* 148px */
    width: 200vw;
    position: relative;
    text-align: center;
    left: 50%;
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-full-bleed-image-container {
    height: 9.25rem; /* 148px */
    width: 100%;
    position: relative;
    text-align: center;
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-full-bleed-image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-scrim-image {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    top: 0;
    left: 0;
    background-size: 100% 100%;
    pointer-events: none;
    background-image: var(--oj-sp-header-welcome-banner-scrim-url);
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm.oj-sp-header-welcome-banner-background-auto .oj-sp-header-welcome-banner-image-container,
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm.oj-sp-header-welcome-banner-background-auto .oj-sp-header-welcome-banner-image {
    object-fit: cover;
    right: initial;
    width: 100%;
  }
  
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-container {
    min-height: 9.25rem; /* 148px */
  }
  
  /* Small Size - Main Panel */
  oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-main-panel {
    width: 100%;
    background-color: var(--oj-sp-header-welcome-banner-background-color-darker-shade);
  }
  
  oj-sp-header-welcome-banner .oj-color-invert .oj-text-field-container {
    background-color: rgba(var(--oj-palette-neutral-rgb-190), 0.2);
  }
  
  /** RTL **/
  html[dir=rtl] oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-image {
    left: initial;
    right: -50%;
  }
  
  html[dir=rtl] oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-image {
    right: initial;
    left: 0;
  }
  
  html[dir=rtl] oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-image-container {
    left: initial;
    right: 50%;
  }
  
  html[dir=rtl] oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-sm) .oj-sp-header-welcome-banner-scrim-image {
    left: initial;
    transform: scaleX(-1);
  }
  
  html[dir=rtl] oj-sp-header-welcome-banner.oj-sp-header-welcome-banner-sm .oj-sp-header-welcome-banner-scrim-image {
    left: initial;
    transform: scaleX(-1);
  }
  oj-sp-header-welcome-banner:not(.oj-sp-header-welcome-banner-background-auto):not(.oj-sp-header-welcome-banner-sm){margin-bottom: 0;}
  .topprefrencebar{padding:28px 0;background:#1e5b72;color:#fff}
  .displaynoflex{flex:none;display: inline-block;padding-right:40px;}
  oj-sp-header-welcome-banner#oj-sp-header-banner-1{background:#5F91A4 no-repeat; background-size: cover;}

  .preferenceButton{text-align: right;}
@media screen and (min-width:1024px) {
    .paragraphtext{width:70%}.search-chips {margin-left:12%;}
}
@media screen and (max-width:950px) {
  oj-sp-header-welcome-banner#oj-sp-header-banner-1 {
    background-size: 1024px auto !important;
  }
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-main-panel{
    width: 60%;
  }
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-image-panel {
    width: 40%;
  }
}
@media screen and (max-width:768px) {
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-main-panel{
      width:100%
  }
  oj-sp-header-welcome-banner > #mobile-header-banner {
    display: block;
  }
  oj-sp-header-welcome-banner .oj-sp-header-welcome-banner-image-panel{display: none;}
  oj-sp-header-welcome-banner#oj-sp-header-banner-1{background-image: none !important;}
  .preferenceButton{text-align: left;}
  .oj-block{display: block;}
  .topsubNav{margin:1em 0;}.topsubNav button{margin:0 10px 0 0}
  .oj-form-control-width-md.oj-select{width: 100% !important;}
}
@media screen and (max-width:567px){
  .topsubNav button{padding:0;font-size: 12px; }
  .topsubNav button .oj-button-label{text-decoration:underline;height:auto}
  .topsubNav button.active_item,.topsubNav button.active_item .oj-button-label{display: none;}
  #dashboardContainer{width: auto;}
  .search-box{padding:0 5px;margin-top: 6px;}
  .search-box .oj-text-field-container.oj-text-field-has-start-slot .oj-text-field-input{padding:5px}
}
.ant-tabs-tab-btn,.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{font-weight: bold;}
.ant-tabs-tab.ant-tabs-tab-active{border-bottom:#658255 4px solid}
.ant-tabs-top > .ant-tabs-nav::before{border-bottom:#e4e1dd 3px solid}
.topprefrencebar .tagStyle, .topprefrencebar .oj-button.oj-default.oj-button-outlined-chrome .oj-button-button
{background: transparent;border:#a5bdc7 1px solid}
.topprefrencebar .text-color-secondary, .topprefrencebar .tagStyle{color:#fff !important}
.oj-actioncard, .oj-actioncard:hover{box-shadow:0px 0px 10px 3px rgb(0 0 0 / 10%) !important}
.carouselsection{position:relative;}  
.carouselsection .anticon{font-size:3em; position:absolute; top:50%; z-index: 1024;margin-top:-20px} 
.carouselsection .anticon.anticon-left-circle{left:-20px}
.carouselsection .anticon.anticon-right-circle{right:0}
.button-white {
  /* solid enabled */
  --oj-button-solid-chrome-bg-color: white;
  --oj-button-solid-chrome-border-color: transparent;
  --oj-button-solid-chrome-text-color: black;
  --oj-button-font-weight: bold;
  /* solid hover */
  --oj-button-solid-chrome-text-color-hover: black;
  /* solid active */
  --oj-button-solid-chrome-text-color-active: black;
}
.button-white:hover {
  --oj-button-solid-chrome-bg-color: #e9e7e7;
}
.button-white:active {
  --oj-button-solid-chrome-bg-color: #d7d5d4;
}
.button-white-black-text {
  /* solid enabled */
  --oj-button-solid-chrome-bg-color: white;
  --oj-button-solid-chrome-border-color: transparent;
  --oj-button-solid-chrome-text-color: black;
  --oj-button-font-weight: bold;
  /* solid hover */
  --oj-button-solid-chrome-text-color-hover: black;
  /* borderless enabled */
  --oj-button-borderless-chrome-border-color: rgba(var(--demo-purple-rgb-160), 0.3);
  --oj-button-borderless-chrome-text-color: black;
  /* borderless hover */
  --oj-button-borderless-chrome-border-color-disabled: rgba(var(--demo-purple-rgb-160), 0.1);
  --oj-button-borderless-chrome-text-color-hover: black;
}
.lsTile{border-radius:5px; margin:0;color:#fff;height:100%;min-height:105px;display:flex;padding:0 20px;flex-direction: column;
  justify-content: center;position:relative;box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 30%);}
.lsTile>div{width:100%}
.lsTile>div.backgroundImage{
  background-image:url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/DEV%2FNeutral_Generic.png');background-size: cover;
height:100%;position:absolute;z-index:0;opacity:0.1;top:0;left:0;mix-blend-mode: overlay;background-position: 50% 50%;
background-repeat: no-repeat;}
.lsTile>div.backgroundImage.CX{background-image:url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/DEV%2FOcean_Netsuite.png');}
.lsTile>div.backgroundImage.SCM{background-image:url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/DEV%2FSienna_SCM.png');}
.lsTile>div.backgroundImage.Finance{background-image:url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/DEV%2FTeal_Finance.png');}
.lsTile>div.backgroundImage.GBU{background-image:url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/DEV%2FLilac_GBUs.png');}
.lsTile>div.backgroundImage.Data{background-image:url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/DEV%2FSlate_Database.png');}
.lsTile>div.backgroundImage.HCM{background-image:url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/DEV%2FRose_HCM.png');}
.ant-carousel .slick-next::before, .anticon-left-circle, .anticon-right-circle{font-size: 3em;color:#000;}
.anticon.slick-disabled{display:none !important;}
.ant-carousel .slick-arrow,.ant-carousel .slick-arrow:hover,.ant-carousel .slick-arrow:focus{color:#000;background: inherit;}
.slick-list{padding-left:0}
.seeAll{margin:0 1em;font-weight: bold;float:right;}

.seeAllLink{font-weight: bold; vertical-align: super;margin-left:15px;cursor:pointer}
/*.ant-carousel .slick-list .slick-slide.slick-active[data-index="0"]{
  margin-left:-50px
}*/
.ant-tabs{overflow: visible;}
.slick-slide oj-sp-object-card {
  margin:1rem 1rem 1rem 0;
}
#userProfile .oj-complete.oj-menu-item a {
  color: #006B8F;
  height: auto !important;
}
#userProfile .oj-complete.oj-menu-item {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mylearn{display: inline-block; vertical-align: middle;}
@media screen and (max-width:1023px) {
  .ant-carousel .slick-list .slick-slide.slick-active[data-index="0"] {margin-left: 0;}
  .oj-typography-body-lg.mylearn{font-size: 85% !important;margin-top: -16px;}
  .oj-sp-header-welcome-banner-main-panel {
    width: 100%;
  }
}
.add-preference-cta {text-align: right;}
.oj-color-invert a{color: #fff;}



.navlistcontainer .ant-input-affix-wrapper{padding:0; position: relative; top: -3px;}
h1.oj-header {color: #fff;}
.navlistcontainer .oj-navigationlist-item-icon{font-size: 1.1rem;}
.navlistcontainer .oj-navigationlist-item-label{font-size:14px;font-weight:normal}
.profiledropdown{min-width:320px;}
.profiledropdown ul{margin:0;padding:0}
.profiledropdown ul li{border-bottom:#ccc 1px solid;list-style: none;background:none}
.profiledropdown ul >li:last-child{border:none}
.profiledropdown li>div{padding:1em}
.profiledropdown a, .profiledropdown a:visited{color:rgb(98, 180, 207);margin-bottom: 5px;display:inline-block}
.ant-drawer-content .header-menu .ml-menu ul, .ant-drawer-content li, .ant-drawer-content li:hover{background: transparent;}
.megamenu{color:#fff;}
.menuitems{width:100%} .megamenu .oj-flex-item{max-width:50%;}
.megamenu ul{padding:0;margin:1em 0 2em 0}
.megamenu ul li{list-style: none; margin:5px 0} .megamenu a{color:#fff}
.librarymenu.ant-drawer-open{width:100% !important;top:55px;}
.mobile-overlaymenu .ant-drawer-wrapper-body{background:rgb(49, 45, 42);color:#fff;padding:0}
.oj-navigationlist-item:hover {background-color: var(--oj-navigation-list-item-bg-color-selected);
  border-color: var(--oj-navigation-list-item-border-color-selected);}
.oj-navigationlist-item:hover a.oj-navigationlist-item-content .oj-navigationlist-item-label,
.oj-navigationlist-item:hover a.oj-navigationlist-item-content .oj-navigationlist-item-icon,
.mobile-overlaymenu a.oj-navigationlist-item-content .oj-navigationlist-item-label, .mobile-overlaymenu .oj-navigationlist-item-icon,
.mobile-overlaymenu .ant-drawer-close, .oj-ux-ico-library{
  color:#fff;
}
.mobile-overlaymenu .oj-navigationlist-item-icon{margin-right:0.50em;}
.draweropen{overflow-y: hidden;}
.industryrecognition p{width:60%;margin:1.5em 0} .industryrecognition .oj-button.oj-default.oj-button-cta-chrome .oj-button-button{padding:0;background: #fff;color:#000; border-color:#333;font-weight:bold}
.industryrecognition .oj-panel{height:100%; background-size: contain;background-repeat: no-repeat;background-position: top right;} 
.explore-free{background:#fef9f2 url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img%2FDEV%2FInfrastructure-5.png');}
.explore-certification{background:#aeccd4 url('https://objectstorage.us-phoenix-1.oraclecloud.com/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img%2FDEV%2FCollaboration%201%201.png')}
.search-box .oj-sp-filter-chip .oj-sp-filter-chip-applied-label{margin-left:5px ;}
@media screen and (min-width:567px) and (max-width:1024px){.oracle-logo{top:0}}
@media screen and (max-width:768px) {
  .profiledropdown{width:100%;border-radius:0;}
  .ant-dropdown{width:100% !important}
  .mobile-overlaymenu .ant-drawer-content-wrapper{width:100% !important}
  .industryrecognition p{width:100%}
  .explore-free{margin-top:1em}
  .sm-margin-bot-1{margin-bottom:2em}
  .add-preference-cta {text-align: center;}
  .search-box input{font-size: 12px;}
  .search-box .search-icon{font-size: 15px;position: relative;top: -2px;}
  .search-box .oj-sp-filter-chip .oj-sp-filter-chip-applied-label, .search-box .oj-sp-filter-chip .oj-sp-filter-chip-divider{display: none;}
  .megamenu{background:rgb(43,32,31) url('https://static.oracle.com/cdn/fnd/gallery/2401.0.1/images/comp-vertical-overview-texture-neutral-sm.png') no-repeat 0 0 !important;background-size: contain !important;}
  .megamenu .oj-flex-item{max-width:100%;}
  .industryrecognition .oj-panel{padding-bottom: 180px !important;background-position: bottom center;}
  .industryrecognition .oj-panel.explore-free{background-position: center 250px;}
  .search-box .oj-inputtext-clear-icon-btn{width:18px !important}
}
#mainpage{position: relative;}
oj-sp-filter-chip .oj-sp-filter-chip-applied:not(.oj-disabled).oj-active .oj-sp-filter-chip-chrome {
  background-color: rgb(34, 126, 158);
  border-color: rgb(34, 126, 158);
  margin: 0 1em 0 0.5em;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-chip-container {
  display: flex;
  align-items: center;
}
.main-header-justify-center{
  justify-content: center;
}
.search-chips .oj-sp-filter-chip span.oj-sp-filter-chip-applied-label, .ant-input-prefix .oj-sp-filter-chip span.oj-sp-filter-chip-applied-label{color:#fff}
.set-max-width{max-width: 1846px; margin: auto;}
#oj_sfs1_h oj-checkboxset{padding-top: 3px;}
#oj_sfs1_h_smartfilters .search_ref_filters{display: inline-block;}
#oj_sfs1_h_smartfilters .ant-collapse-borderless, #oj_sfs1_h_smartfilters .ant-checkbox-wrapper{background: none;color:#fff}
#oj_sfs1_h_smartfilters .oj-collapsible-header, 
#oj_sfs1_h_smartfilters .oj-choice-item, #oj_sfs1_h_smartfilters .oj-radiocheckbox-icon{display: none;}
#oj_sfs1_h_smartfilters .oj-choice-item.oj-selected{display: flex;}
#oj_sfs1_h_smartfilters .ant-collapse-borderless > .ant-collapse-item{border: 0;}
#oj_sfs1_h_smartfilters .ant-collapse-content > .ant-collapse-content-box{padding:0}
#oj_sfs1_h_smartfilters .oj-checkbox-label{margin: 0;padding:5px 5px 0 5px;cursor: pointer;}
#oj_sfs1_h_smartfilters .oj-choice-item.oj-selected{ background-color: rgb(34, 126, 158);
  border-color: rgb(34, 126, 158); margin: 0 1em 0 0;border-radius:4px; padding:4px}
#oj_sfs1_h_smartfilters .search_ref_filters.oj-sm-padding-3x-top,
#oj_sfs1_h_smartfilters .search_ref_filters.oj-sm-margin-6x-horizontal,  
#oj_sfs1_h_smartfilters .search_ref_filters.oj-sm-margin-2x-vertical{margin: 0!important;padding: 0!important;}
#oj_sfs1_h_smartfilters .oj-radiocheckbox-label-text::after {
    font-family: 'ojuxIconFont'!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    speak: none;
    content: '\f1e2';
    vertical-align: middle;
    font-size: 20px;
    margin-left: 10px;
}
#oj_sfs1_h_smartfilters .refine-section-container, #oj_sfs1_h_smartfilters .oj-button-sm{height: auto;background: none;display: inline-block; vertical-align: middle;margin:0}
#oj_sfs1_h_smartfilters .oj-button-button{padding:6px;width: auto;}
#oj_sfs1_h_smartfilters .search_ref_filters{border: none;}
#oj_sfs1_h_smartfilters .search_ref_filters .oj-collapsible-header-wrapper{display: none;}
.oj-button-toggle.oj-selected{cursor: default;}

body, body:hover, *{
scrollbar-width: auto !important;
scrollbar-color:auto
}
:hover {
  scrollbar-color: auto !important
}
::-webkit-scrollbar {
  width: auto;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: auto; 
  -webkit-border-radius: auto;
  border-radius: auto;
  background:auto;    
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius:auto;
  border-radius:auto;
  background:auto; 
  -webkit-box-shadow:auto; 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: auto; 
}
.recentlyadded-section .ant-carousel .slick-list{ height: 100% !important;}
oj-action-card .seeAlldate{position:absolute;bottom:30px;font-weight: bold;}

#headerProfileDropdown.oj-hover{background: none;}
#headerProfileDropdown button, #headerProfileDropdown button oj-avatar{padding:0}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
  text-shadow: 0 0 .25px currentColor;
}
.ant-tabs-ink-bar{background: #658255 !important;}
.oj-navigationlist-element a:hover{text-decoration: none;}
.oj-navigationlist-element a:hover{text-decoration: none;}

#userProfile {
    font-size: 14px;
}
#userProfile > .oj-menu-item {
    height: 35px;
}
#userProfile > .oj-menu-item:not(.oj-disabled).oj-focus > a {
    background:none;
}

.profiledropdown slot .pad-left, .profiledropdown .pad-left{padding-left: var(--oj-menu-text-to-edge-padding); margin-top: 0.5em;}.profiledropdown slot span.pad-left{display: inline-block;}
.profiledropdown slot.pad-left{display: block;padding-left: var(--oj-menu-text-to-edge-padding);padding-right: var(--oj-menu-text-to-edge-padding);}
#mainnav .oj-navigationlist-item.oj-navigationlist-item-last-child {
  margin-inline-end: var(--oj-navigation-list-item-margin);
}
.oj-navigationlist-horizontal .oj-navigationlist-item.oj-hover:not(.oj-selected){background: none;}

a.oj-tabbar-item-content{text-decoration: none;color: var(--oj-tab-bar-item-label-color);
  font-weight: var(--oj-tab-bar-item-font-weight);font-size: var(--oj-tab-bar-item-font-size);
  text-align: center;-webkit-touch-callout: none; -webkit-user-select: none; 
   -khtml-user-select: none;  -moz-user-select: none; -ms-user-select: none; user-select: none;}
 .oj-tabbar-item-label+.oj-tabbar-item-label,.oj-tabbar-item-label:empty{display: none;}
  .oj-tabbar-listview-container::before{
    border-bottom: #e4e1dd 3px solid;position: absolute;
    right: 0;
    left: 0;
    content: ''; bottom: 0;z-index: 0;
}
li.oj-tabbar-item-element, oj-tab-bar{position: relative;}
#profileTabs .oj-tabbar-listview-container::before{display: none;}
.oj-text-field .oj-text-field-input::selection, .oj-text-field .oj-combobox-input::selection, .oj-text-field .oj-select-chosen::selection {
  background-color: lightblue;color:#000
}
@media screen and (max-width:820px) {.mylearn{display: none;}}
a.logoSection:hover{text-decoration: none;}


.mlmenu-list-container {
  display: flex;
  flex-direction: row;
}
.mlmenu-list-item {
  margin-inline-end: var(--oj-navigation-list-item-margin);
  box-sizing: border-box;
  min-height: 2.75rem;
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: 0 0 var(--oj-private-navigation-list-item-border-width) 0;
  border-color: var(--oj-core-bg-color-content);
  margin-inline-end: 1em;
}
.mlmenu-list-item-selected {
  border-color: var(--oj-navigation-list-item-border-color-selected);
}
.mlmenu-list-item > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding-bottom: 2px;
}
.mlmenu-list-item-selected > a { color: #fff; }
.mlmenu-list-item-selected .oj-navigationlist-item-icon { color: #fff; }
.mlmenu-list-item:not(.mlmenu-list-item-selected) > a { color: var(--oj-navigation-list-item-label-color); }
.mlmenu-list-item:not(.mlmenu-list-item-selected) .oj-navigationlist-item-icon { color: var(--oj-navigation-list-item-label-color); }
.mlmenu-list-item:hover > a { color: #fff; }
.mlmenu-list-item:hover .oj-navigationlist-item-icon { color: #fff; }

.mlmenu-list-container.mlmenu-start{
  flex-direction: column;
  width: 100%;
}
.mlmenu-start .mlmenu-list-item{
  padding-left: 21px;
  height: 48px;
  margin: 0px;
  cursor: pointer;
  border-width: 0 0 0 var(--oj-private-navigation-list-item-border-width);
}
.mlmenu-start .mlmenu-list-item > a {
  flex-direction: row;
  padding-bottom: 0px;
}
.mlmenu-start .mlmenu-list-item-selected { background-color: var(--oj-core-bg-color-active); }
.mlmenu-start .mlmenu-list-item:hover{ background-color: var(--oj-core-bg-color-hover); }
.mlmenu-start .mlmenu-list-item .oj-navigationlist-item-icon { padding-right: 10px; }

#mainHeader {
  outline: none;
}

#headerProfileDropdown:focus, #mainHeader a:focus  {
  outline: dotted 1px var(--oj-core-focus-border-color);
  outline-offset: 1px;
}

.nav-display-flex {
  align-items: center;
  display: flex;
  align-self: center;
}
.oj-ux-ico-oracle-o:before{font-size: 1.5em; margin-right: 0.2em; color: #C74634;}

a div.oj-navigationlist-item-red-dot {
  background-color: rgb(var(--oj-palette-danger-rgb-90));
  display: inline-block;
  position: relative;  
  height: 8px;
  width: 8px;
  top: -18px;
  left: 6px;
  border-radius: 50%;
}
