.steps {
    margin: 30px auto 0px auto;
} 

.steps img {
    width: 24px;
    display: block;
    margin: auto;
}

.steps .pending {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: black;
    color: white;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    display: block;
    margin: auto;
}

.steps span {
    font-weight: 700;
}