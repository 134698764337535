:root {
    /* TODO: Remove these CSS variables once JET exposes similar ones */
    /* stylelint-disable-next-line comment-empty-line-before */
  
    /* Variables (see: http://design.oraclecorp.com/variables/#visual_specs) */
    --oj-sp-filter-chip-high-opacity: 1;
    --oj-sp-filter-chip-outline-color-rgb: var(--oj-palette-neutral-rgb-190);
    --oj-sp-filter-chip-outline-offset: 0;
    --oj-sp-filter-chip-outline-opacity: var(--oj-sp-filter-chip-high-opacity);
    --oj-sp-filter-chip-outline-style: dotted;
    --oj-sp-filter-chip-outline-width: 1px;
  
    /* Filter Chip (see: http://design.oraclecorp.com/filter-chips#visual_specs) */
    --oj-sp-filter-chip-border-radius: var(--oj-button-border-radius);
    --oj-sp-filter-chip-applied-bg-color: var(--oj-palette-info-rgb-100);
    --oj-sp-filter-chip-applied-border-color: var(--oj-sp-filter-chip-applied-bg-color);
    --oj-sp-filter-chip-non-applied-bg-color: var(--oj-palette-neutral-rgb-100);
    --oj-sp-filter-chip-non-applied-border-color: var(--oj-sp-filter-chip-non-applied-bg-color);
    --oj-sp-filter-chip-close-anchor-bg-color: var(--oj-palette-info-rgb-100);
  }
  
  oj-sp-filter-chip {
    box-sizing: border-box;
    display: inline-block;
  }
  
  .oj-sp-color-invert-bg oj-sp-filter-chip {
    /* TODO: Remove these CSS variables once JET exposes similar ones */
    --oj-sp-filter-chip-brand-dark-50: 240, 204, 113;
    /* stylelint-disable-next-line custom-property-empty-line-before */
    --oj-sp-filter-chip-outline-color-rgb: var(--oj-palette-neutral-rgb-0);
  
    /* stylelint-disable-next-line custom-property-empty-line-before */
    --oj-sp-filter-chip-applied-bg-color: var(--oj-sp-filter-chip-brand-dark-50);
    --oj-sp-filter-chip-applied-border-color: var(--oj-sp-filter-chip-applied-bg-color);
    --oj-sp-filter-chip-non-applied-bg-color: var(--oj-palette-neutral-rgb-10);
    --oj-sp-filter-chip-non-applied-border-color: var(--oj-sp-filter-chip-non-applied-bg-color);
    --oj-sp-filter-chip-close-anchor-bg-color: var(--oj-sp-filter-chip-brand-dark-50);
  }
  
  /* Required for simulated outline */
  oj-sp-filter-chip .oj-sp-filter-chip .oj-sp-filter-chip-focusable {
    position: relative;
  }
  
  /* Required for simulated outline */
  oj-sp-filter-chip .oj-sp-filter-chip .oj-sp-filter-chip-focusable.oj-focus {
    outline: none;
  }
  
  /* Required for simulated outline */
  oj-sp-filter-chip :not(.oj-disabled) .oj-sp-filter-chip-focusable.oj-focus::after {
    border: var(--oj-sp-filter-chip-outline-width) var(--oj-sp-filter-chip-outline-style) rgba(var(--oj-sp-filter-chip-outline-color-rgb), var(--oj-sp-filter-chip-outline-opacity));
    border-radius: var(--oj-sp-filter-chip-border-radius);
    bottom: var(--oj-sp-filter-chip-outline-offset);
    content: '';
    left: var(--oj-sp-filter-chip-outline-offset);
    position: absolute;
    right: var(--oj-sp-filter-chip-outline-offset);
    top: var(--oj-sp-filter-chip-outline-offset);
  }
  
  /* Required for simulated outline */
  html:not([dir='rtl']) oj-sp-filter-chip .oj-sp-filter-chip-non-applied:not(.oj-disabled) .oj-sp-filter-chip-non-applied-label-focusable.oj-sp-filter-chip-focusable.oj-focus::after,
  html[dir='rtl'] oj-sp-filter-chip .oj-sp-filter-chip-non-applied:not(.oj-disabled) .oj-sp-filter-chip-non-applied-value-count-focusable.oj-sp-filter-chip-focusable.oj-focus::after {
    border-radius: var(--oj-sp-filter-chip-border-radius) 0 0 var(--oj-sp-filter-chip-border-radius);
  }
  
  /* Required for simulated outline */
  html:not([dir='rtl']) oj-sp-filter-chip .oj-sp-filter-chip-non-applied:not(.oj-disabled) .oj-sp-filter-chip-non-applied-value-count-focusable.oj-sp-filter-chip-focusable.oj-focus::after,
  html[dir='rtl'] oj-sp-filter-chip .oj-sp-filter-chip-non-applied:not(.oj-disabled) .oj-sp-filter-chip-non-applied-label-focusable.oj-sp-filter-chip-focusable.oj-focus::after {
    border-radius: 0 var(--oj-sp-filter-chip-border-radius) var(--oj-sp-filter-chip-border-radius) 0;
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-divider {
    display: inline-block;
    height: 1rem;
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-close-anchor {
    background-color: rgba(255, 255, 255, 0);
    border-radius: var(--oj-sp-filter-chip-border-radius);
    transition: background-color;
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-clickable {
    cursor: pointer;
  }
  
  oj-sp-filter-chip .oj-disabled .oj-sp-filter-chip-close-anchor:hover,
  oj-sp-filter-chip .oj-disabled .oj-sp-filter-chip-clickable,
  oj-sp-filter-chip .oj-sp-filter-chip-applied .oj-sp-filter-chip-clickable:not(.oj-sp-filter-chip-applied-actionable) {
    cursor: default;
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-applied:not(.oj-disabled).oj-active .oj-sp-filter-chip-close-anchor {
    background-color: rgba(var(--oj-sp-filter-chip-close-anchor-bg-color), 0.24);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-applied:not(.oj-disabled) .oj-sp-filter-chip-close-anchor:hover {
    background-color: rgba(var(--oj-sp-filter-chip-close-anchor-bg-color), 0.16);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-applied-actionable .oj-sp-filter-chip-applied-value {
    color: rgb(var(--oj-palette-info-rgb-120));
  }
  
  .oj-sp-color-invert-bg oj-sp-filter-chip .oj-sp-filter-chip-applied-actionable .oj-sp-filter-chip-applied-value {
    color: rgb(var(--oj-sp-filter-chip-brand-dark-50));
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-chrome {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 255, 255, 0);
    border-radius: var(--oj-sp-filter-chip-border-radius);
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    display: inline-flex;
    height: 2rem;
    transition: background-color, border-color;
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-applied .oj-sp-filter-chip-chrome {
    background-color: rgba(var(--oj-sp-filter-chip-applied-bg-color), 0.08);
    border-color: rgba(var(--oj-sp-filter-chip-applied-border-color), 0.08);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-non-applied .oj-sp-filter-chip-chrome {
    border-color: rgba(var(--oj-sp-filter-chip-non-applied-border-color), 0.25);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-applied.oj-disabled .oj-sp-filter-chip-chrome {
    background-color: var(--oj-button-solid-chrome-bg-color-disabled);
    border-color: var(--oj-button-solid-chrome-bg-color-disabled);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-applied:not(.oj-disabled).oj-active .oj-sp-filter-chip-chrome {
    background-color: rgba(var(--oj-sp-filter-chip-applied-bg-color), 0.24);
    border-color: rgba(var(--oj-sp-filter-chip-applied-border-color), 0.24);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-applied:not(.oj-disabled):hover .oj-sp-filter-chip-chrome {
    background-color: rgba(var(--oj-sp-filter-chip-applied-bg-color), 0.16);
    border-color: rgba(var(--oj-sp-filter-chip-applied-border-color), 0.16);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-non-applied:not(.oj-disabled).oj-active .oj-sp-filter-chip-chrome {
    background-color: rgba(var(--oj-sp-filter-chip-non-applied-bg-color), 0.2);
  }
  
  oj-sp-filter-chip .oj-sp-filter-chip-non-applied:not(.oj-disabled):hover .oj-sp-filter-chip-chrome {
    background-color: rgba(var(--oj-sp-filter-chip-non-applied-bg-color), 0.15);
  }

  oj-sp-filter-chip .oj-sp-filter-chip-applied-label {
    color: #161513;
    font-weight: 600;
  }
  
  #oj-input-text-filter-chip oj-sp-filter-chip oj-button {
    height: 2rem;
  }
  
  #oj-input-text-filter-chip oj-sp-filter-chip oj-button button:focus {
    outline: 2px dotted white !important;
    outline-offset: -6px !important;
  }
 
  