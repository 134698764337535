.notification-drawer.oj-complete.oj-drawer-end {
  width: 40vw;
}

.notification-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: baseline;
  align-items: center;
}

.notification-activation-action {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
}

.notification-activation {
  margin-right: auto;
}

.notification-activation b {
  color: gray;
  font-size: 0.9em;
}
