.learner-role-profile-dropdown > :not(.oj-disabled) > a:active {
    color: black !important;
}
.learner-role-profile-dropdown > :not(.oj-disabled) > a:hover {
    color: black !important;
}
.learner-role-profile-dropdown > :not(.oj-disabled) > a:focus {
    color: black !important;
}
.oj-disabled .oj-menu-item > a, .oj-menu-item.oj-disabled > a:active {
    color: var(--oj-core-text-color-disabled) !important;
}
.oj-disabled .oj-menu-item > a, .oj-menu-item.oj-disabled > a:hover {
    color: var(--oj-core-text-color-disabled) !important;
}
.oj-disabled .oj-menu-item > a, .oj-menu-item.oj-disabled > a:focus {
    color: var(--oj-core-text-color-disabled) !important;
}
#mainHeader > div > span > div > oj-menu-button > button:active, #mainHeader > div > span > div > oj-menu-button > button:focus {
    outline: dotted 1px var(--oj-core-focus-border-color);
    outline-offset: 1px;
}

oj-dialog oj-button:focus-within {
    outline: 1px dotted #201E1C;
    outline-offset: 1px;
}
