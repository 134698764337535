#playlist .oj-listview-item {
  border-bottom: none !important;
}

#playlist li:focus-visible {
  outline: 2px dotted #201E1C;
  outline-offset: 0px;
}

#playlist .playlistComponent {
  opacity: 1;
  margin: 0;
}

#playlist li:first-child {
  margin-top: 3px !important;
}

#playlist li:last-child {
  margin-bottom: 3px !important;
}
