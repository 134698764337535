#quiz-modal {
	line-height: 24px !important;
}
#quizModalDialog .oj-dialog-header {
    padding: 28px 28px 0;
}
#quiz-modal .quizWelcomeContent {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	min-height: 220px;
}
#quiz-modal #title {
	padding: 100px 0;
	position: relative;
	box-sizing: border-box;
}
#quiz-modal .qwc {
	font-size: 1.5em;
	text-align: center;
}

#quiz-modal .qwc h2 {
	font-size: 1.3em;
}

#quiz-modal .qwc h2 span {
	font-weight: bold;
}

#quiz-modal #goBtn,
#quiz-modal #submitBtn {
	cursor: default !important;
	position: initial !important;
}
#quiz-modal #submitBtn {
	margin-top: 30px;
}
#quiz-modal #goBtn input,
#quiz-modal #submitBtn input#btn {
	height: 36px;
	font-size: 14px;
	color: #FFFFFF;
	line-height: 17px;
	align-items: center;
	background-color: rgb(49, 45, 42);
	border: 1px solid #111111;
	border-radius: 4px;
	box-sizing: border-box;
	cursor: pointer;
	font-family: "Oracle Sans", -apple-system, "system-ui", "Segoe UI", "Helvetica Neue", Arial, sans-serif;
	font-weight: 600;
	width: 121.516px;
	padding: 5px 16px;
	position: absolute;
	bottom: 0;
	right: 0;
}
#quiz-modal #goBtn input:hover,
#quiz-modal #submitBtn input#btn:hover {
	background-color: rgb(58, 54, 50);
}

#quiz-modal #quizModal .optionOutCss,
#quiz-modal #quizModal .optionOverCss,
#quiz-modal #quizModal .optionBox {
	background: transparent;
}

/* #col2 {
	left: 20px !important;
} */
#quiz-modal #quizoptionContainer #col2 {
    display: none !important;
}
#quiz-modal #quiz #quizoptionContainer #col1 {
	min-height: 400px;
	padding: 0px;
}

#quiz-modal #quiz #quizoptionContainer #col1 > div p {
	padding: 5px 35px;
	font-size: 20px;
	font-weight: bold;
	color: #444444;
	margin: 10px !important;
}

#quiz-modal #quiz #quizoptionContainer #col1 > div:first-child {
	margin-top: 20px;
	margin-bottom: 25px;
}

#quiz-modal #quiz #quizoptionContainer #col1 > div:first-child p {
	font-size: 24px;
	font-weight: bold;
	color: #111111;
	padding: 10px;
	line-height: 28px;
}

/* *********************************************************************/
#quiz-modal #quizModal .passbg {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
#quiz-modal #quizModal .failbg {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
#quiz-modal #quizModal .timesupbg {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
#quiz-modal #quizModal .orangebg {
	background: rgba(253, 199, 70, 1);
}
#quiz-modal #quizModal .border {
	border: none;
}
#quiz-modal #quizModal .lightBox {
	background: rgba(255, 255, 255, 0.9);
	padding: 20px;
	border-radius: 0px;
	-moz-border-radius: 0px;
	border: none;
}
#quiz-modal #quizModal .timeoutContainer {
	height: 100%;
} 
#quiz-modal #quizModal .scoreContainer {
	height: 100%;
} 
#quiz-modal #quizModal .glow {
}
#quiz-modal #quizModal .white {
}
#quiz-modal #quizModal .black {
	color: #111;
}
#quiz-modal #quizModal .grey {
	color: #666;
}
#quiz-modal #quizModal .orange {
	color: #bd7a00;
}
#quiz-modal #quizModal .cent {
	text-align: center;
}
#quiz-modal #quizModal .p_16 {
	font-size: 16px;
	margin: 10px !important;
}
#quiz-modal #quizModal .p_24 {
	font-size: 18px;
}
#quiz-modal #quizModal .p_30 {
	font-size: 30px;
	line-height: 35px;
}
#quiz-modal #quizModal .p_32 {
	font-size: 32px;
	line-height: 40px;
	color: #000000;
}
#quiz-modal #quizModal .p_42 {
	font-size: 42px;
	line-height: 40px;
}
#quiz-modal #quizModal .mcqRadioBtn {
	width: 12px;
	height: 12px;
	background: #fff;
	border: 1px solid #999;
	border-radius: 10px;
	-moz-border-radius: 10px;
}
#quiz-modal #quizModal .optionBox {
	background: transparent;
	color: #161513;
	padding: 0px 8px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom: 12px;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
	border: 2px solid #6b8f63;
	overflow: hidden !important;
}
#quiz-modal #quizModal .optionOutCss,
#quiz-modal #quizModal .optionOverCss {
	background: transparent;
	color: #161513;
	padding: 0px 8px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom: 12px;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
	border: 2px solid #6b8f63;
}
#quiz-modal #quizModal .optionOverCss:hover {
	transform: translate(10px, 0px);
}
#quiz-modal #quizModal .optionOverCss {
	background: rgba(107, 143, 99, 0.15) !important;
}
#quiz-modal #quizModal .optionOverCss p {
	color: #161513;
}

#quiz-modal #quizModal .vertical-align {
	position: relative !important;
}

#quiz-modal #quizModal .timerContainer {
	text-align: center;
}
#quiz-modal #quizModal .timer {
	margin-top: 0px;
	background: transparent none no-repeat 0px 0px;
	display: none;
	opacity: 0.5;
	filter: alpha(opacity=50);
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
	-moz-opacity: 0.5;
	position: relative !important;
}
#quiz-modal #quizModal .btnQuit {
	width: auto !important;
	margin: auto;
	text-align: center;
	background-color: #ae562c;
	font-weight: 600;
	font-size: 14px;
	color: #fcfbfa !important;
	-webkit-transition: background-color 100ms linear;
	transition: background-color 100ms linear;
	padding: 12px 27px !important;
	border-radius: 3em;
	border: none;
	cursor: pointer;
}
#quiz-modal #quizModal .btnQuit:hover {
	background-color: #9f4b23;
}
#quiz-modal #quizoptionContainer #submitBtn {
	margin-bottom: 20px !important;
}

#quiz-modal #fb {
	margin-top: 30px !important;
	left: 20px !important;
	margin-bottom: 20px;
}
#quiz-modal #quiz-modal .w3-button {
	z-index: 1000;
}
#quiz-modal #failTextBox {
	margin-bottom: 0px !important;
	height: auto !important;
}
#title {
	padding-bottom: 15px;
}
#quiz-modal #nextQBtn {
	margin-bottom: 15px;
}
#quiz-modal #fail_txt {
	margin-bottom: 15px;
}


#quiz-modal #timerRow {
	left: inherit !important;
	right: 0;
}


#quiz-modal .w3-modal-content {
	background: transparent;
}

#quiz-modal #quizModal .optionOutCss,
#quiz-modal #quizModal .optionOverCss,
#quiz-modal #quizModal .optionBox,
#quiz-modal #quiz #quizoptionContainer #col1 > div {
	padding: 0px 8px;
	margin-bottom: 12px;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
	border: none;
}

#quiz-modal #quizModal .optionOverCss:hover {
	transform: translate(0px, 0px);
}
#quiz-modal #quizModal .optionOverCss {
	background: rgba(143, 143, 143, 0.15) !important;
}

#quiz-modal #quizModal .optionBox {
	overflow: initial !important;
}

#quiz-modal #quiz #quizoptionContainer #col1 div.optionOutCss p:before,
#quiz-modal #quiz #quizoptionContainer #col1 div.optionOverCss p:before {
	position: absolute;
	left: 28px;
	content: "\f32b";
	margin-top: 3px;
    display: block;
    position: absolute;
    font-family: ojuxIconFont!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    speak: none;
}

#quiz-modal #quiz #quizoptionContainer #col1.typeRadio > div.optionOutCss p:before,
#quiz-modal #quiz #quizoptionContainer #col1.typeRadio > div.optionOverCss p:before { 
	content: "\f32b";
}
#quiz-modal #quiz #quizoptionContainer #col1.typeCheck > div.optionOutCss p:before,
#quiz-modal #quiz #quizoptionContainer #col1.typeCheck > div.optionOverCss p:before {
	content: "\f1c0";
}
#quiz-modal #quiz #quizoptionContainer #col1.typeRadio > div.optionOutCss.quizSelected2 p:before,
#quiz-modal #quiz #quizoptionContainer #col1.typeRadio > div.optionOverCss.quizSelected2 p:before {
	content: "\f32c";
}
#quiz-modal #quiz #quizoptionContainer #col1.typeCheck > div.optionOverCss.quizSelected2 p:before {
	content: "\f484";
}
#quiz-modal #quizModal #failTextBox.vertical-align,
#quiz-modal #quizModal #passTextBox.vertical-align {
	min-height: 400px;
	position: relative;
}

#quiz-modal #failTextBox #fail_txt,
#quiz-modal #passTextBox #pass_txt {
	position: initial !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 380px;
	justify-content: center;
}

#quiz-modal #passTextBox #pass_txt {
	flex-direction: initial;
}

#quiz-modal #passTextBox #pass_txt .logo {
	margin: 0 30px;
}

#quiz-modal #passTextBox #pass_txt .passTxt {
	text-align: center;
}

#quiz-modal #passTextBox #pass_txt .passTxt .orange {
	font-weight: bold;
	color: #759c6c;
}

#quiz-modal #failTextBox #fail_txt p,
#quiz-modal #passTextBox #pass_txt p {
	margin: 10px 0;
}

#quiz-modal #failTextBox #fail_txt #goBtn,
#quiz-modal #passTextBox #pass_txt #goBtn {
	display: none;
}

@media only screen and (max-width: 600px) {
	#quiz-modal #quiz #quizoptionContainer #col1 > div:first-child {
		margin-top: 0;
	}
	#quiz-modal #quiz #quizoptionContainer #col1 > div:first-child p {
		font-size: 20px;
		line-height: normal;
	}
	#quiz-modal #quiz #quizoptionContainer #col1 > div p {
		font-size: 18px;
		line-height: normal;
	}
	#quiz-modal #quiz #quizoptionContainer #col1 div.optionOutCss p:before, 
	#quiz-modal #quiz #quizoptionContainer #col1 div.optionOverCss p:before {
		margin-top: 4px;
	}
	#quiz-modal #quiz #quizoptionContainer #col1 {
		padding-bottom: 30px;
	}
}

#quizModalDialog #quizQueSubmit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: #ffffff74;
    z-index: 99;
    display: none;
}