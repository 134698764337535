.preference-selection {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.preference-selection .preferenceSelectionWrapper {
    margin-left: 20%;
    margin-right: 20%;
}

#preferenceSelectionContainer {
    width: 100%;
}

@media (max-width: 768px) {
    .preference-selection .preferenceSelectionWrapper {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (max-width: 375px) {
    .preference-selection .preferenceSelectionWrapper {
        margin-left: 0;
        margin-right: 0;
    }
}
