.activation-success {
  width: 100%;
  min-height: 937px;
  background-size: cover;
  background-repeat: no-repeat;
}

.activation-success .activation-success-content {
  width: 100%;
  margin:50px 20% 0 20%;
}

.activation-success .activation-success-content h2 {
  font-size: 2em;
  font-weight: 800;
}

.activation-success .activation-success-content .subscriptions {
  flex-direction: column;
}

.activation-success .activation-success-content .subscriptions .subscription {
  justify-content: space-between;
}

.activation-success .activation-success-content .subscriptions .subscription .subscription-title {
  margin-right: 30px;
}

.activation-success .activation-success-content .subscriptions .subscription .subscription-title span {
  margin-right: 10px;
  margin-top: 5px;
}

.activation-success .activation-success-content .subscriptions .subscription .subscription-title span.oj-icon-color-info {
  padding-top: 5px;
}

.activation-success .activation-success-content .subscriptions .subscription .subscription-title span {
  font-family: "OracleSansVF";
  font-weight: 600;
}

.activation-success .activation-success-content .subscriptions .subscription .subscription-access span.access-label {
  color: grey;
  font-size: 0.8rem;
}

.activation-success .activation-success-content span.products-info {
  margin-top: 20px;
  font-style: italic;
  color: grey;
}

.activation-success .activation-success-content h2 {
  margin-top: 20px;
}

.activation-success .personalize-start {
  margin-top: 10%;
  margin-bottom: 10%;
}

.activation-success-start-btn {
  width: 15rem;
}

.userSubscription-list .ant-card-body p{margin: 0;}
.userSubscription-list .ant-card-body .oj-typography-body-2xs{float: right;margin-top: 5px;}
.activation-success .oj-ux-ico-playlist-add{vertical-align: middle;}
.loadingProducts{text-align: center; margin-bottom: 3em;}
.userSubscription-list .ant-card-body p.itemname{margin-left: 18px;}
.showMoreBtn{margin:0 0 1em 0;height: 32px,;line-height: '32px'}
.showMoreBtn button{border: none;box-shadow: none;font-weight: bold;}

@media (max-width: 1024px) {
  .activation-success {
    min-height: 1024px;
  }

  .activation-success .activation-success-content {
    margin-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .activation-success .activation-success-content .subscriptions .subscription .subscription-title {
    margin-right: 0;
    flex-wrap: nowrap;
  }
}

@media (max-width: 480px) {
  .activation-success {
    min-height: 800px;
  }

  .activation-success .personalize-start oj-button  {
    width: 100%;
  }
}