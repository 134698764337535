.mobiledisplay{
    display: none;
}
.listbox-wrapper {
    pointer-events: auto;
    border-style: solid;
    padding: 12px 22px 22px;
    border-width: 1px 1px 1px 7px;
    user-select: none;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
   /**  border-color: rgb(214, 214, 214) rgb(214, 214, 214) rgb(214, 214, 214) rgb(69, 124, 139); **/
    line-height: 12px;
    overflow-wrap: break-word;
    font-size: 13px;
    word-spacing: 1px;
    text-align: left;
    font-weight: bold;
    color: rgb(48, 44, 41);
    cursor: pointer;
    overflow: visible;
    margin-top: 30px;
    width: 833px;
    min-height: 130px;
    transition: box-shadow 0.3s;
}

.listbox-wrapper:hover{
    box-shadow: rgba(0, 0, 0, 0.3) 8px 9px 5px;
}


.listbox-full-card {
    pointer-events: auto;
    display: none;
    border-style: none;
    background-color: rgb(255, 255, 255);
    border-width: 0px;
    border-color: rgba(66, 66, 66, 0.33);
    overflow: visible;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.3) 8px 9px 10px;
    width: 851px;
    min-height: 150px;
    transition: display 0.5s, min-height 0.5s, transform 1s;
}


.listbox-init-card {
    pointer-events: auto;
    border-style: solid;
    border-width: 0px;
    border-color: rgb(198, 198, 198);
    overflow: visible;
    padding-right: 60px;
    line-height: 1.5em;
    width: 851px;
    min-height: 150px;
    transition: margin-top 0.3s, box-shadow 0.3s, transform 0.6s;
}


.listbox-init-card.expanded {
    margin-top: -30px;
    background-color: white;
    box-shadow: rgb(219, 219, 219) 0px 2px 7px;
}

.listbox-init-card.expanded .listbox-full-card {
    display: block;
    min-height: 300px;
}

.desc-summary {
    font-weight: normal;
}

.expanded-cont {
    padding: 30px;
    box-shadow: rgb(219, 219, 219) 0px 2px 7px;
}

.expanded-topics {
    padding: 30px;
}

.expanded-topics li {
    background-color: white;
}

.tile-icon {
    font-size: large;

}
.oj-listview-item{
    border-bottom: #ccc 1px solid;
}
.oj-listview-card-group .oj-listview-item{border: none;}
.oj-listview-element li, .oj-listview-element li:hover,
oj-sp-expandable-list-item .oj-sp-expandable-list-item-header:hover{
    cursor: pointer;
    background: #fff;
}
.oj-ux-ico-certification{
    font-size: 2em;
}
.ant-btn-icon-only.ant-tooltip-open{
    color: #ccc
}
.oj-sp-card-footer-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    }
.oj-listview-container{border: none;}
@media only screen and (max-width: 1023px){
    #filtersection{
        height: 100%;
      width: 103%;
      position: fixed;
      z-index: 1024;
      left: 0;
      top: 0;
      background-color: rgba(0,0,0, 0.4);
      overflow-x: hidden;
      transition: 0.5s;
      max-width:103%;
    }
    #filtersection .oj-sp-smart-filter-content-sticky{
        position: relative;
        top:0;
        width: 100%;
    }
}
@media only screen and (max-width: 767px) {
.oj-sp-list-item-template-primary-col{flex: 100%;}
.oj-sp-list-item-template-toolbar-container, .oj-sp-list-item-template-badge-top-container {
    flex: 0 1 auto;
}

.mobiledisplay{
    display: block;
}
}
.ant-col ant-col-12.formatSecCont-0-2-34{
    flex: auto;
    width: auto;
    display: block;
    max-width: max-content;
    margin-right: 45px;
}
.oj-badge-article{background-color: #628F8D;}
.ant-timeline {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum';
    margin: 0;
    padding: 0;
    list-style: none;position: relative;
}.ant-timeline-item {
    position: relative;
    margin: 0;
    padding-bottom: 20px;
    font-size: 14px;
    list-style: none;
}.ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px solid #f0f0f0;
}.ant-timeline-item-head {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 100px;
}.ant-timeline-item-content {
    position: relative;
    top: -7.001px;
    margin: 0 0 0 26px;
    word-break: break-word;
}.ant-timeline-item-head-custom {
    position: absolute;
    top: 5.5px;
    left: 5px;
    width: auto;
    height: auto;
    margin-top: 0;
    padding: 3px 1px;
    line-height: 1;
    text-align: center;
    border: 0;
    border-radius: 0;
    transform: translate(-50%, -50%);
}
.ant-timeline li:last-child .ant-timeline-item-tail{display: none;}

.oj-listitemlayout-start-padding > p {
    line-height: 0.5cm;
}
.lpCircleIcon{background:#3d192b;
    color:#fff;
    padding: 0.5em;
    border-radius: 100%;
    font-size: 1.4em;vertical-align: middle;margin-right: 0.5em;}

.oj-sp-list-item-template-module {
    cursor: pointer;
}
