.oj-pagingcontrol-nav-arrow.oj-component-icon.oj-clickable-icon-nocontext.oj-disabled {
  pointer-events: none;
}

.oj-pagingcontrol-nav-pages-links,
.oj-pagingcontrol-nav-input-summary.oj-label-inline,
.oj-pagingcontrol-nav-arrow-section {
  display: inline-block !important;
}

.oj-pagingcontrol-nav-page:focus-within {
  outline: 2px dotted #201e1c;
  outline-offset: 2px;
}

@media (max-width: 767px) {
  .oj-pagingcontrol-nav.oj-pagingcontrol-nav-standard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .oj-pagingcontrol-nav-arrow-section {
    border: none !important;
  }
}

:root {
  --oj-core-focus-border-color: white;
}

oj-table#progressHistoryTable {
  --oj-core-focus-border-color: #201e1c;
}

#progressHistoryWrapper {
  position: sticky;
  width: 100%;
}

#progressHistoryTable {
  position: relative;
  width: 100%;
  min-height: 34rem;
  max-height: calc(70vh - 10px);
  overflow: auto;
}

#progressHistoryTable a {
    color: var(--oj-link-text-color) !important;
}
