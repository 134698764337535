#doc-course-page .oj-button-icon {
  margin: 0 !important;
  font-size: 24px !important;
  padding: 0 5px;
}

#doc-course-page button.oj-button-button:focus, a:focus {
  outline: 2px dotted #201E1C;
  outline-offset: 2px;
}

.unauthorizedModalDialogP {
    text-align: center !important;
}

#unauthorizedModalDialog > div > div {
    background-image: none !important;
}

div > #unauthorizedModalDialogCenter {
    width: 100%;
    text-align: center;
}

#unauthorizedModalDialogCenter > oj-button, #unauthorizedModalDialogCenter > a > oj-button {
    width: 50%;
}

.unauthorizedModalDialogHr {
    border-top: 1px solid #000!important;
    margin: 4vh 0 !important;
}

.unauthorizedModalDialogNetsuite2H4 {
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    color: #505050;
}

.unauthorizedModalDialogNetsuite2p {
    text-align: left;
}

.article-course-collapsible > .oj-collapsible-header-wrapper > .oj-collapsible-header {
    font-weight: normal !important;
    padding: 2em 0 !important;
}

.article-course-collapsible > .oj-collapsible-header-wrapper {
    border-width: 0px !important;
}