
.nps {
  border: 1px solid black;
  border-radius: 4px;
  max-width: 100%;
  display: inline-block;
  height: 36px;
  font-size: 18px;
  overflow: hidden;
  user-select: none;
  background-color: #e8e8e8;
}

.selection {
  display: inline-block;
  text-align: center;
  min-width: 10px;
  max-height: 100%;
  padding: 8px 11px;
  border-left: 1px solid black;
  }

  .selection div:hover {
    cursor: pointer;
  }

  .selection div:first-child {
    border-left: none;
  }

.selected, .selection:hover {
  background-color: black;
  color: white;
}

.selection:hover {
  opacity: 65%;
}

.feedbackAction {
    position: fixed;
    right:    0;
    bottom:   0;
    z-index: 99;
}

#submitDrawerBtn.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.feedBack-form .feedBack-form-header {position: relative;}
.feedBack-form [id*='endButtonCloserAskTheInstructorDrawer'] {position: absolute; right:2em;top: 1em;}
.labelfor, .feedBack-form .feedback-field .oj-label-group label {
  font-size: var(--oj-typography-subheading-xs-font-size)!important;display: inline-block;
  font-weight: var(--oj-typography-subheading-xs-font-weight)!important; padding-bottom:0.5em;
  line-height: var(--oj-typography-subheading-xs-line-height)!important;color: #100f0e;}
  .oj-text-field-middle .oj-label-group label{font-size: inherit;}
  #feedbackBtn button, #feedbackBtn2 button {
    background-color: var(--oj-core-info-1); color: var(--oj-core-info-contrast);
  }

.rate_video {
  display: block;
  font-size: 0;
  font-weight: 500;
}
.rate_video label {
  cursor: pointer;
}
.rate_video span {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 1em;
  font-size: 16px;
  color: #312D2A  ;
  border-top: solid 1px #312D2A;
  border-bottom: solid 1px #312D2A;
  border-right: solid 1px #312D2A;
}
.rate_video label:first-child span {
  border-left: solid 1px #312D2A;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}
.rate_video label:last-child span {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
.rate_video input[type=radio] {
  position:absolute;
	left:-1000000em;
	right:-1000000em;
	display: inline-block;
  z-index: -1;
}
.rate_video input[type=radio]:focus ~ span {
  outline: #312D2A dotted 2px;
}
.rate_video input[type=radio]:checked ~ span, .rate_video input[type=radio]:hover ~ span {
  background: #312D2A;
  color: white;
}
fieldset {
  border: 0 none;
}

#sentMsg.center {
    text-align: center;
}

.feedback-reminder {
	transform: translate(0px, 0px);
	z-index: 999;
}

.feedback-reminder {
	position: fixed;
	bottom: 190px;
	right: 8px;
	width: 400px;
	background: #fff;
	transform: translate(0px, 190px);
	box-shadow: 0px 0px 8px 3px rgb(0 0 0 / 15%),
		0px 0px 3px 1px rgb(0 0 0 / 5%);
	transition: transform 300ms 300ms cubic-bezier(0.68, -0.12, 0.85, 1.23);
}

.feedback-rem-content {
	padding: 8px 30px 30px;
	background-color: #f1efed;
	color: #333;
}

.feedback-rem-close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 22px;
	height: 22px;
	cursor: pointer;
	transition: all 0.1s ease-out;
}

.feedback-rem-close:before, .feedback-rem-close:after {
	content: "";
	width: 28px;
	height: 2px;
	background: #888;
	position: absolute;
	top: 10px;
	left: -3px;
	transform: rotate(-45deg);
	transition: all 0.3s ease-out;
}

.feedback-rem-close:after {
	transform: rotate(45deg);
	transition: all 0.3s ease-out;
}

.feedback-rem-close:hover:before, .feedback-rem-close:hover:after {
	background: #333;
}
.feedback-rem-txt {
	width: 100%;
	text-align: center;
	margin-top: 60px;
	font-weight: 700;
	font-size: 18px;
}

.feedback-rem-go {
	width: 100%;
	text-align: center;
	margin: 30px 0 0 0;
}

.feedback-rem-go button {
	border: 0;
	padding: 10px 25px;
	border-radius: 5px;
	cursor: pointer;
}

#submitDrawerBtn.oj-button:focus-within {
  outline: 2px dotted #201E1C;
  outline-offset: 2px;
}

@media (max-width: 600px) {
  .uk-container-expand {
    margin-bottom: 95px;
  }
}

.oj-text-field-label-inside .oj-textarea-input {
  padding-top: 10px !important;
}
