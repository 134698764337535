.flipped-badge {
    pointer-events: auto; 
    border-style: none; 
    border-width: 0px; 
    user-select: none; 
    transform-origin: 50% 50%; 
    padding: 19px 4px 4px; 
    border-radius: 7px; 
    border-color: rgb(160, 160, 160); 
    overflow-wrap: break-word; 
    font-size: 9px; 
    text-align: center; 
    color: rgb(255, 255, 255); 
    cursor: pointer; 
    overflow: visible; 
    width: 157px; 
    height: 174px; 
    box-shadow: rgba(0, 0, 0, 0.21) 4px 4px 6px; 
    background-color: rgb(33, 31, 28); 
}

.badge {
    pointer-events: auto; 
    background-repeat: no-repeat; 
    overflow: visible; 
    cursor: pointer; 
    background-size: 100% 100%;  
    width: 250px;
    height: 127px; 
}

.cert-badge {
    pointer-events: auto; 
    background-repeat: no-repeat; 
    overflow: visible; 
    cursor: pointer; 
    background-size: 100% 100%; 
    width: 127px; 
    height: 127px; 
}


.text-badge{
    pointer-events: auto; 
    position: absolute; 
    padding: 8px; 
    color: rgb(0, 0, 0); 
    display: inline; 
    font-family: "Noto Sans"; 
    font-size: 14px; 
    overflow-wrap: break-word; 
    overflow: visible; 
    z-index: 12; 
    word-spacing: -1px; 
    text-align: center; 
    width: 154px; 
    height: 41px; 
    top: 140px; 
    left: 372px;
}