#live-session-page .breadcrumbs-center-a-items{
  padding-top: 5px;
  font-size: 12px;
  color: #000 !important;
  margin-left: auto;
  line-height: 1.33;
  text-align: right;
  max-width: 35%
  }
  #live-session-page .oj-accordion-collapsible>.oj-collapsible-header {
    font-weight: normal !important;
    padding: 1em 0 !important;
  }
  #live-session-page .md-display-block {
    display:block !important;
  }
  #live-session-page .oj-collapsible-header-icon {
    position: absolute;
    right: 0;
    font-size: 2em;
  }
  #live-session-page .sidebar-slot .oj-collapsible-header-icon {
    font-size: 1.2em !important;
  }
  #live-session-page .sidebar-slot .oj-tabbar-listview-container::before {
    border-bottom: none !important;
  }
  #live-session-page .sidebar-slot .oj-tabbar-vertical.oj-tabbar-icon-only {
    --oj-tab-bar-icon-margin: 0 0.5rem !important;
  }
  #live-session-page .sidebar-slot .oj-tabbar-item.oj-selected .oj-tabbar-item-text {
    color: var(--oj-tab-bar-item-label-color-selected);
    font-weight: var(--oj-tab-bar-item-font-weight-selected) !important;
  }
  #live-session-page .sidebar-slot .oj-divider-bottom {
    border-bottom: none !important;
  }
  #live-session-page .oj-accordion-collapsible>.oj-collapsible-header>.oj-collapsible-header-icon {
    padding: 6px 12px 8px 10px !important;
  }
  #live-session-page .oj-accordion-collapsible > .oj-collapsible-header {
    font-weight: normal !important;
    padding: 2em 0 !important;
  }
  #live-session-page .sidebar-slot .oj-accordion-collapsible>.oj-collapsible-header {
    font-weight: normal !important;
    padding: 1em 0 !important;
  }
  #live-session-page .sidebar-slot .oj-sm-pos-top {
    top: 0.3em !important;
  }
  #live-session-page .sidebar-slot #arrow {
    position: absolute;
    bottom: 20px;
    left: 9px;
  }
  #live-session-page .oj-collapsible-header.stepHeader {
    min-height: 60px !important;
  }
  @media screen and (max-width: 599.9px) {
    #live-session-page .sidebar-slot .oj-tabbar-horizontal {
      --oj-tab-bar-item-margin: 0 !important;
    }
  }
  @media screen and (min-width: 1024px) {
    #live-session-page .sidebar-slot .tabContent {
      height: 100%;
      width: 345px !important;
    }
    #live-session-page .main-slot {
      height: 100%;
      overflow-y: auto;
      scrollbar-width: none !important;
    }
    #live-session-page .liveSessionPage {
      height: 100%;
    }
    #live-session-page .sidebar-slot .liveSessionSection {
      /*height: 100vh;*/
      height: 100%;
      position: relative;
    }
    #live-session-page .sidebar-slot .liveSessionSecTopics{
      height: 100%;
    }
    #live-session-page .sidebar-slot .demo-tabbar-container {
      height: 100%;
      border-right: 0 solid var(--oj-core-divider-color);
      border-width: 1px;
      position: relative;
    }
  }
  #live-session-page .sidebar-slot .oj-listview-item {
    border-bottom: 1px solid var(--oj-core-divider-color) !important;
  }
  #live-session-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-content {
    flex-direction: column !important;
    justify-content: space-around !important;
  }
  #live-session-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-content {
    padding: 4px !important;
  }
  #live-session-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-icon {
    padding: 4px !important;
  }
  #live-session-page .oj-tabbar-vertical .oj-tabbar-item.oj-selected {
      border-color: #5F7D4F;
  }
  #live-session-page .oj-tabbar-horizontal.oj-tabbar-icon-only .oj-tabbar-item.oj-selected {
    border-color:  #5F7D4F;
  }
  #live-session-page .sidebar-slot .oj-listview-element.oj-component-initnode li {
    background: RGB(var(--oj-palette-neutral-rgb-10)) !important;
  }
  @media print and (orientation: portrait),
  screen and (min-width: 600px) and (max-width: 1023.9px) {
    /** md-only */
    #live-session-page .oj-tabbar-horizontal .oj-tabbar-item {
      flex: 0 0 auto !important;
      padding: 4px !important;
    }
  }
  #live-session-page .liveSessionTopics:hover, #live-session-page .liveSessionTopics:focus-within{
    background-color: #E4F1F7;
  }
  #live-session-page .oj-collapsible-wrapper .oj-collapsible-content>a:focus-within{
    outline: 1px solid #201E1C;
    outline-offset: 1px;
  }
  #live-session-page .liveSessionDetailsSection ul.oj-listview-element.oj-component-initnode li {
    background-color: RGB(var(--oj-palette-neutoudl_videral-rgb-10)) !important;
  }
  #live-session-page .oj-progress-circle.oj-progress-circle-sm {
    width: 0.9rem !important;
    height: 0.9rem !important;
  }
  #live-session-page .guidesList {
    height: calc(100vh - 152px);
    overflow: auto;
  }
  #labDetails {
    height: calc(100vh - 152px);
    overflow: auto;
  }
  #live-session-page .oj-listitemlayout-grid {
    padding-inline-end: 0.05rem !important;
    padding-inline-start: 0rem !important;
  }
  @media only screen and (max-width: 599px) {
    #live-session-conveyor-belt {
        width: 70vw;
    }
  }

  oj-button button:disabled {
    background-color: var(--oj-button-solid-chrome-bg-color-disabled) !important;
    border-color: var(--oj-button-solid-chrome-border-color-disabled) !important;
    color: var(--oj-button-solid-chrome-text-color-disabled) !important;
    background-image: none !important;
  }
  #live-session-page .liveSessionPage .warning-banner {
    width: 100%;
  }
  .liveSessionPage .warning-banner .view-schedule {
    font-weight: bolder;
    box-shadow: none !important;
    color: #000;
  }

  #live-session-page .liveSessionBtnLinks {
    width: 100% !important;
    display: none;
  }
  .instructor-details-section {
    cursor: default !important;
  }

  @media only screen and (max-width: 1023.9px) {
    #live-session-page .liveSessionBtnLinks {
      display: flex;
    }
  }

  #alertDialog .oj-dialog-header {
    background: none !important;
  }

  #live-session-page .liveSessionPage a.oj-typography-body-sm {
    color: #0274dd;
  }

  #live-session-page .sidebar-slot .liveSessionSection .detailsSection a {
    color: #016ccf;
  }