.ask-instructor-netsuite-drawer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 150vh;
    max-width: 250vh;
}

.ask-instructor-full-height {
    height: 100%;
}

.ask-instructor-width {
    width: 100%;
    max-width: 100vh !important;
}

@media screen and (max-width:768px) {
    .ask-instructor-netsuite-drawer {
        min-width: auto;
    }
}
