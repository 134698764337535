.bg-image-wrapper {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #D6D6D6;
}

.separator::before {
  margin-right: 18px;
}

.separator::after {
  margin-left: 18px;
}

.linear-gradient {
  background: linear-gradient(90deg, #462705 0%, #928B86 100%);
  height: 20px;
}

.login-container {
  background-color: #FBF9F8;
  border-radius: 8px;
  overflow: hidden;
  width: 532px;
  height: 582px;
}

.login-container-content {
  margin-top: 40px;
  margin-bottom: 70px;
  margin-left: 70px;
  margin-right: 70px;
}

.login-container .login-container-content oj-button button {
  border-radius: 4px !important;
}

@media screen and (max-width:1027px) {
  .login-container {
    width: 516px;
    height: 582px;
  }
}

@media screen and (max-width:768px) {
  .login-container {
    width: 460px;
    height: 582px;
  }

  .login-container-content {
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media screen and (max-width:440px) {
  .login-container {
    width: auto;
    margin: 0 15px;
  }

  .login-container-content {
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }
}