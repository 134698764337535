@keyframes spinner {
	to {transform: rotate(360deg);}
  }
   .spinner {
	  text-align: center;
	  position: relative;
	  vertical-align: middle;
	  display: table;
  }
  .spinner .middle {
	  display: table-cell;
	  vertical-align: middle;
  }
  .small {
	width: 20px;
	height: 20px;
  }
  .medium {
	width: 30px;
	height: 30px;
  }
  .large {
	width: 50px;
	height: 50px;
  }
  .spinner .middle .loading-content {
	  border: 2px solid #000;
	  border-bottom-color: transparent;
	  border-radius: 50%;
	  display: inline-block;
	  box-sizing: border-box;
	  animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
	  0% {
		  transform: rotate(0deg);
	  }
	  100% {
		  transform: rotate(360deg);
	  }
  }
  