#learningPathDescription .video-js-component {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 6px;
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 1392px) {
  #learningPathDescription .video-js-component {
    height: 180px;
    width: 320px;
  }
  .bc-player-default_default .vjs-big-play-button {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 600px) {
  #learningPathDescription .video-js-component {
    height: 152px;
    width: 270px;
  }
  .bc-player-default_default .vjs-big-play-button {
    font-size: 2em;
  }
}
