
  #lab-details-page .oj-accordion-collapsible>.oj-collapsible-header {
    font-weight: normal !important;
    padding: 1em 0 !important;
  }
  #lab-details-page .oj-collapsible-header.stepHeader {
    display: flex;
  }
  #lab-details-page .oj-accordion-collapsible>.oj-collapsible-wrapper>.oj-collapsible-content {
    padding: 0;
  }

  #lab-details-page button.oj-button-button:focus-visible {
    outline: 1px solid #201E1C;
    outline-offset: 1px;
  }

  @media screen and (min-width: 769px) {
    #lab-details-page .sidebarSection {
      min-width: 400px;
      width: 35vw;
      overflow-x: auto;
    } 
  }
  @media screen and (max-width: 768px) {
    #lab-details-page .sidebarSection {
      width: 100%;
      overflow-x: auto;
    }
  }

  #lab-details-page .oj-collapsible-header.stepHeader {
    min-height: 60px !important;
  }
  #lab-details-page .oj-tabbar-horizontal:not(.oj-tabbar-icon-only) .oj-tabbar-item.oj-selected {
    border-color: #5F7D4F;
  }
  #lab-details-page .oj-collapsible-wrapper .oj-collapsible-content>a:focus-within{
    outline: 1px solid #201E1C;
    outline-offset: 1px;
  }
  #labDetails {
    height: calc(100vh - 152px);
    overflow: auto;
  }
  @media only screen and (max-width: 599px) {
    #lab-details-conveyor-belt {
        width: 70vw;
    }
  }

#lab-details-page .calendar-container {
  max-width: 280px !important;
  width: 100% !important;
}

/* lab related CSS from course page */
#labDetails .oj-complete>.oj-collapsible-wrapper>.oj-collapsible-content {
  padding: 0 !important;
}

#labDetails .oj-complete>.oj-collapsible-header {
  font-weight: normal !important;
  padding: 1em 0 !important;
}

#labCalenderListView .labCalendarHeader {
  font-size: 13px;
  font-weight: 700!important;
  color: #646464;
  text-align: center;
  padding: 10px 5px!important;
}

#labAssignment #slotfilmStrip .oj-filmstrip-pages-container {
  border: 0px !important;
}

.oj-listview-element.oj-component-initnode li.labCalendarHeaderLi {
  background: #e0dcd7 !important;
}

#labDetails .oj-collapsible-wrapper oj-collapsible {
  border-bottom: 1px solid #ccc;
}

#labDetails .lab-msg-common {
  line-height: 16px;
  font-weight: 600;
  color: #222222;
  font-size: 13px;
}

#labDetails .lab-msg-dont-need {
  color: var(--oj-core-warning-1)!important;
  font-weight: 600 !important;
  font-size: 13px !important;
}

#labDetails .lab-msg-gesdb-color {
  color: #333333;
}

#labDetails .lab-msg-gesdb-info {
  font-weight: 700;
  color: #333333;
}

#labDetails .lab-msg-gesdb-cred div {
  color: #333333;
  font-size: 13px;
}
#labDetails .lab-msg-gesdb-cred div b {
  font-weight: 600 !important;
  display: inline-block;
  min-width: 120px;
}

#labDetails #extraCloudConnectionDetails {
  font-size: 13px;
  line-height: 18px;
}
#labDetails #extraCloudConnectionDetails p,
#labDetails .extraCloudConnectionDetails2 p,
#labDetails .extraCloudConnectionDetails2 ol li {
  font-size: 13px;
  line-height: 18px;
  white-space: pre-line;
}
#labDetails p a {
  word-break: break-word;
}
#labDetails pre {
  word-break: break-word;
  white-space: pre-line;
  margin: 0;
  line-height: 16px;
  color: #333333;
}
#labDetails #extraCloudConnectionDetails p > br {
  display: block;
  content: 'A';
  margin-top: 7px;
}
#labDetails #extraCloudConnectionDetails p font,
#labDetails #extraCloudConnectionDetails strong font {
  color: var(--oj-core-warning-1) !important;
}
#labDetails .extraCloudConnectionDetails2 ol {
  padding: 0 0 0 15px;
}
#labDetails .extraCloudConnectionDetails2 ol li {
  margin-top: 7px;
}
/* loveleen code for ticket 17259 */
.darkoutline >button:focus {
  outline-color: #000 !important;
  }
/* loveleen code for ticket 17259 */