
oj-sp-image-card {
  display: block;
  margin: 0.5rem;
}

oj-sp-image-card:not(.oj-complete) {
    visibility: hidden;
}

oj-sp-image-card a:hover {
  text-decoration: none;
}

oj-sp-image-card .oj-sp-image-card-parent-container {
  width: 305px;
  margin: 0;
  visibility: visible !important;
  /*box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 30%);*/
  background-color: white;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-container {
  width: 100%;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-body {
  padding-bottom: 20px;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-content .oj-sp-image-card-footer .oj-sp-card-footer-container .oj-sp-card-footer-toolbar-container button {
  left: 50%;
  border: 1px solid grey;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-body .oj-sp-image-card-text-tall {
  height: 120px;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-body .oj-sp-image-card-text-short {
  height: 80px;
  margin: 0px 0px 30px 15px;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-body .oj-sp-image-card-text-short span {
  font-family:"Oracle Sans";
  font-size: 16px;
  line-height: 20px;
  color: #161513;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-thumbnail {
    height: 250px;
    margin: 20px;
    background-repeat:no-repeat;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-thumbnail img {
    height: 100%;
    width: 100%;
}

oj-sp-image-card .oj-sp-image-card-parent-container .oj-sp-image-card-thumbnail .oj-sp-image-card-title span {
  font-family: "Oracle Sans";
  font-size: 1.5em;
  line-height: 28px;
  color: white
}

oj-sp-image-card > a.oj-image-card-anchor {
  display: inline-flex;
}

@media (max-width: 768px) {
  oj-sp-image-card .oj-sp-image-card-parent-container {
    width: 280px;
  }
}

oj-sp-object-card oj-action-card:focus {
    outline: dotted 2px black !important;
    outline-offset: -1px !important;
}

.oj-sp-filter-chip a:focus {
    outline: dotted 2px black;
    outline-offset: -1px;
}

oj-sp-image-card oj-action-card:focus {
    outline: dotted 2px black !important;
    outline-offset: -1px !important;
}

.item a:focus {
    outline: dotted 2px black;
    outline-offset: -1px;
}
