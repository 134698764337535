#liveSessionsHeader {
    background-color: #f1efed;
}

#breadcrumbsliveEvents a {
    color: black !important;
}

#liveEventsTitle {
    margin-bottom: 0rem;
}

#searchLiveEvents>div>span>span {
    color: gray;
}

#liveEventsDescription p {
    font-size: 1rem !important;
}

#searchLiveEvents {
    margin-bottom: 1.3rem;
}

#live-events-tabs a:hover .oj-navigationlist-item-label {
    color: black !important;
}

#live-events-tabs a:focus .oj-navigationlist-item-label {
    color: black !important;
}

#live-events-tabs a:active .oj-navigationlist-item-label {
    color: black !important;
}

#live-events-tabs .oj-navigationlist-item.oj-selected {
    border-color: green;
}

@media (max-width: 768px) {
    #headerLiveeventsResultTitle {
        padding-left: 0rem;
    }
}

#liveEventscommunityAndShareSection>div>oj-menu-button {
    margin-inline-start: 0px !important;
}

.oj-avatar-live-events .oj-avatar-initials {
    font-weight: 100;
}

@media (max-width: 600px) {
    
    .textFiltersLiveEvents {
        text-align: center;
    }
    #menuButtonLiveEvents {
        margin-left: 1rem;
    }
}

@media (min-width: 600px) {
    #buttonFiltersLiveEvents {
        width: 300px;
    }
}

.discussion__stats-stat svg,
.o__event-date {
    margin-right: 8px;
}

div.mt-1 {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4rem;
    font-family: 'Oracle Sans';
    vertical-align: baseline;
    line-height: 1.2;
    text-align: center;
}

.o__event-date--wrapper {
    border-radius: 4px;
    border: solid 1px #312d2a;
    background-color: #514c47;
    display: flex;
    flex-direction: column;
    width: 60px;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 5.5rem;
    width: 4.5rem;
}

#liveEventscommunityAndShareSectionMovile a {
    margin: 0px;
}

#liveEventscommunityAndShareSectionMovile oj-menu-button {
    margin-right: 0.5rem;
    margin-inline-start: -.3rem !important;
}

a.live-events-list:visited {
    color: black !important;
}

a.live-events-list {
    color: black !important;
}                   

a.live-events-list:focus div.live-events-item {
    background-color: #f1efed !important;
}

a.live-events-list:active div.live-events-item {
    background-color: #f1efed !important;
}

a.live-events-list:hover div.live-events-item {
    background-color: #f1efed !important;
}

#buttonCommunityLiveEvents > button:focus {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}

a.live-events-tabs:focus {
    outline: 2px dotted #201E1C !important;
    outline-offset: -2px !important;
}

a.live-events-tabs:active {
    outline: 2px dotted #201E1C !important;
    outline-offset: -2px !important;
}

a.live-events-tabs:hover {
    outline: 2px dotted #201E1C !important;
    outline-offset: -2px !important;
}

#listviewliveevents > ul > li.oj-listview-empty-text {
    display: none;
}

#live-events-main-list {
    list-style-type: none;
}

div.live-events-item {
    padding-top: var(--oj-collection-list-cell-padding-vertical);
    padding-bottom: var(--oj-collection-list-cell-padding-vertical);
    padding-inline-start: var(--oj-core-gutter-start,var(--oj-list-view-item-padding-horizontal));
    padding-inline-end: var(--oj-core-gutter-end,var(--oj-list-view-item-padding-horizontal));
    background: white;
    background-image: linear-gradient(var(--oj-core-divider-color), var(--oj-core-divider-color));
    background-position: 0 0;
    background-size: 100% 1px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-top: 0px solid transparent;
}