body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 20px;
}
body::-webkit-scrollbar-track {
  background: #f5f4f2;
}
#course-page .oj-accordion-collapsible>.oj-collapsible-header {
  font-weight: normal !important;
  padding: 1em 0 !important;
}
#course-page .oj-collapsible-header-icon {
  position: absolute;
  right: 0;
  font-size: 2em;
}
#course-page .checkboxset-wrapper:focus-within, #course-page #goBackTop:focus-within, #course-page #videoRatingId:focus-within {
  outline: 2px dotted #201E1C;
  outline-offset: 2px;
}
#course-page .courseList {
  list-style: none;
  position: relative;
}
#course-page .courseList li {
  background: none;
}
#course-page .sidebar-slot .oj-collapsible-header-icon {
  font-size: 1.2em !important;
}
#course-page .sidebar-slot .oj-tabbar-listview-container::before {
  border-bottom: none !important;
}
#course-page .sidebar-slot .oj-tabbar-vertical.oj-tabbar-icon-only {
  --oj-tab-bar-icon-margin: 0 0.5rem !important;
}
#course-page .sidebar-slot .oj-tabbar-item.oj-selected .oj-tabbar-item-text {
  color: var(--oj-tab-bar-item-label-color-selected);
  font-weight: var(--oj-tab-bar-item-font-weight-selected) !important;
}
#course-page .sidebar-slot .oj-divider-bottom {
  border-bottom: none !important;
}
#course-page .oj-collapsible>.oj-collapsible-header>.oj-collapsible-header-icon {
  padding: 6px 12px 8px 10px !important;
}
#course-page .oj-accordion-collapsible>.oj-collapsible-wrapper>.oj-collapsible-content,
#course-page #labDetails .oj-complete>.oj-collapsible-wrapper>.oj-collapsible-content {
  padding: 0 !important;
}
#course-page .sidebar-slot .oj-accordion-collapsible>.oj-collapsible-header,
#course-page .sidebar-slot #labDetails .oj-complete>.oj-collapsible-header {
  font-weight: normal !important;
  padding: 1em 0 !important;
}
#course-page .sidebar-slot .playListComplete {
  font-size: 17px;
  top: 1px !important;
  left: -1px;
  position: relative;
}
#course-page .sidebar-slot .playListIncomplete {
  top: 3px !important;
  position: relative;
  margin-right: 2.5px;
}
#course-page .sidebar-slot .playListIncomplete.playListInprogress .oj-progress-circle-value {
  border-color: #fb9334;
}
#course-page .sidebar-slot .playListIncomplete.playListInprogress .oj-progress-circle-tracker {
  border-color: rgb(251 147 52 / 20%);
}
#course-page .sidebar-slot #arrow {
  position: absolute;
  bottom: 20px;
  left: 9px;
}
#course-page .sidebar-slot .oj-text-field-input {
  font-size: 85% !important;
}
#course-page .sidebar-slot .oj-text-field .oj-text-field-container {
  background-color: RGB(var(--oj-palette-neutral-rgb-40)) !important;
  border-style: none !important;
}
#course-page .sidebar-slot .oj-label-group {
  font-weight: normal !important;
}
#course-page .sidebar-slot {
   height: 100%;
}
#course-page .sidebar-slot .oj-choice-item:focus-within {
  outline: none !important;
}
#course-page .sidebar-slot .courseList {
  overflow-y: auto;
  scrollbar-width: thin !important;
  scrollbar-color: #7a7a7a #f5f4f2 !important;
}

#course-page .sidebar-slot .courseList::-webkit-scrollbar {
  width: 8px;
}
#course-page .sidebar-slot .courseList::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 20px;
}
#course-page .sidebar-slot .courseList::-webkit-scrollbar-track {
  background: #f5f4f2;
}
#course-page .main-slot::-webkit-scrollbar { display: none; }
#course-page .sidebar-slot .oj-progress-bar-value {
  background-color: #161513 !important;
}
#course-page .sidebar-slot .courseTopics .oj-badge {
  margin-right: 0 !important;
  border: solid 1px #b5b5b5 !important;
}
@media only screen and (min-width: 600px) and (max-width: 1230px) {
  #course-page .odd-display-block {
    display: block !important;
  }
}
#course-page .courseDuration {
  position: absolute;
  bottom: 0;
  z-index: 99;
  height: 75px;
  width: 345px;
}
#course-page .courseVideoButtons {
  width: 100% !important;
  display: none;
}
@media print and (orientation: portrait),
screen and (min-width: 600px) and (max-width: 1023.9px) {
  /** md-only */
  #course-page .courseDuration {
    position: sticky;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: 70px;
    left: 0;
  }
  #course-page .sidebar-slot .courseList {
    height: calc(-50px + 100vh) !important;
  }
}
#course-page .oj-collapsible-header.stepHeader {
  min-height: 50px !important;
}
@media screen and (max-width: 599.9px) {
  #course-page .sidebar-slot .oj-tabbar-horizontal {
    --oj-tab-bar-item-margin: 0 !important;
  }
  #course-page .sidebar-slot .courseList {
    height: calc(-50px + 100vh) !important;
  }

  /* small only screen */
  #course-page .courseDuration {
    position: sticky;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: 70px;
    left: 0;
  }
  #course-page .topics {
    max-width: 160px;
  }
  #course-page .courseTitle{
    max-width: 210px;
    display: inline-block;
  }
  #course-page .course-components:has(.introduction-component) {
    width:100%;
    height:50vh !important;
  }
  #course-page .course-components {
    width:100%;
    height:50vh !important;
  }
  #course-page .course-components.introduction-component {
    height:50vh !important;
    width:100%;
  }
  #course-page .course-components .document-component, #course-page .course-components .quiz-component, #course-page .course-components .upk-component, #course-page .course-components .scorm-component, #course-page .course-components .assessment-component {
    height:50vh !important;
    width:100%;
  }
}
#course-page .compActive .courseTopics {
  padding-inline-start: var(--oj-core-spacing-3x) !important;
 }
 #course-page .sidebar-slot .courseList {
  height: calc(-156px + 100%);
  overflow-y: auto;
  scrollbar-width: thin !important;
  scrollbar-color: #7a7a7a #fff !important;
}
@media screen and (min-width: 1023px) {
  #course-page .courseDetails {
    width: 474px;
  }
  #course-page .topics {
    max-width: 195px;
  }
  #course-page .sidebar-slot .tabContent {
    height: 100%;
    width: 345px !important;
  }
  #course-page .courseTitle {
    max-width: 250px;
    display: inline-block;
  }
  #course-page .main-slot {
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none !important;
  }
  #course-page {
    height: calc(100vh - 52px);
    overflow: hidden;
    }
  #course-page .coursePage {
    height: 100%;
  }
  #course-page .w3-container {
    height: 100%;
  }
  #course-page .sidebar-slot .courseSection,.sidebar-slot .playlistTabPanel{
    height: 100%;
    position: relative;
  }
  #course-page .sidebar-slot .courseSecTopics{
    height: 100%;
  }
  #course-page .sidebar-slot .playlistSection {
    height: calc(100% - 85px);
  }
  
  #course-page .sidebar-slot .demo-tabbar-container {
    height: 100%;
    border-right: 0 solid var(--oj-core-divider-color);
    border-width: 1px;
    position: relative;
  }
  #course-page .compActive .courseTopics {
    padding-inline-start: var(--oj-core-spacing-4x) !important;
    cursor: pointer;
  }
}
#course-page .oj-inputsearch-search-icon {
  color: #000 !important;
}
#course-page .oj-avatar {
  height: var(--oj-avatar-size) !important;
  width: var(--oj-avatar-size) !important;
  margin-top: 0 !important;
  display: grid !important;
}
#course-page ul.oj-listview-element.oj-component-initnode li,
#course-page .sidebar-slot .oj-listview-element.oj-component-initnode li {
  background-color: #ebe8e3;
}
#course-page .sidebar-slot .oj-listview-item {
  border-bottom: 1px solid var(--oj-core-divider-color) !important;
}
#course-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-content {
  flex-direction: column !important;
  justify-content: space-around !important;
}
#course-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-content {
  padding: 4px !important;
}
#course-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-icon {
  padding: 4px !important;
}
#course-page .oj-tabbar-vertical .oj-tabbar-item.oj-selected {
  border: 0 !important;
  background: #e0dcd6;
}
#course-page .course-sidebar-tab-bar-ul li {
  border: 0 !important;
}
#course-page .course-sidebar-tab-bar-ul li .oj-tabbar-item.oj-selected {
  border: 0 !important;
  background: #e0dcd6;
}
#course-page .course-sidebar-tab-bar-ul li a {
  flex-direction: column;
  padding: 4px 0px 4px 2px !important;
}
#course-page .course-sidebar-tab-bar-ul li a .oj-tabbar-item-label {
  line-height: 0;
  order: unset;
  text-align: unset;
}
#course-page .course-sidebar-tab-bar-ul li.oj-tabbar-item.oj-selected {
  border: 0 !important;
  background: #e0dcd6;
}

@media only screen and (max-width: 1023.9px) {
  #course-page .course-sidebar-tab-bar-ul {
    margin-bottom: 15px;
    border-bottom: 1px solid #e0dcd6;
  }
  #course-page .course-sidebar-tab-bar-ul li {
    margin: 0px !important;
    padding-left: 10px !important;
    padding-right: 20px !important;
  }
  #course-page .course-sidebar-tab-bar-ul li a {
    flex-direction: unset;
    margin: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #course-page .course-sidebar-tab-bar-ul li a .oj-tabbar-item-label {
    line-height: 1.2;
    order: unset;
    text-align: unset;
  }
  #course-page .course-sidebar-tab-bar-ul li a .oj-tabbar-item-icon {
    margin: 0rem 0.375rem;
  }
  #course-page .courseVideoButtons {
    display: flex;
  }
}
@media only screen and (max-width: 410px) {
  #course-page .course-sidebar-tab-bar-ul li {
    margin: 0px !important;
    padding-left: 4px !important;
    padding-right: 8px !important;
  }
  #course-page .course-sidebar-tab-bar-ul li a {
    padding: 0px 0px 0px 0px !important;
  }
  #course-page .course-sidebar-tab-bar-ul li a .oj-tabbar-item-icon {
    margin: 0rem 0.375rem;
  }
}
#course-page .announcement-icon .oj-avatar-background {
  background-image: none;
}
#course-page .info-icon .oj-avatar-background {
  background-image: none;
}
#course-page .announcement-icon .oj-avatar {
  background-color: #fbdab6;
  color: var(--oj-heading-text-color);
}
#course-page .info-icon .oj-avatar {
  background-color: #e8e5d9;
  color: var(--oj-heading-text-color);
}
@media print and (orientation: portrait),
screen and (min-width: 600px) and (max-width: 1023.9px) {
  /** md-only */
  #course-page .oj-tabbar-horizontal .oj-tabbar-item {
    flex: 0 0 auto !important;
  }
  #course-page .course-components {
    width:100%;
    height:50vh !important;
  }
  #course-page .course-components.introduction-component, #course-page .course-components .quiz-component, #course-page .course-components .upk-component, #course-page .course-components .scorm-component, #course-page .course-components .assessment-component, #course-page .course-components .document-component {
    height:50vh !important;
    width:100%;
  }
}
#course-page .courseTopics:hover, #course-page .courseTopics:focus-within{
  background-color: #E4F1F7;
}
#course-page .oj-collapsible-wrapper .oj-collapsible-content>a:focus-within{
  outline: 1px solid #201E1C;
  outline-offset: 1px;
}
#course-page .compActive{
  border-left: solid 5px #e69200;
  background-color: rgb(235 233 231);
}
#course-page .course-components {
  width:100%;
  height:70vh;
}
#course-page .course-components:has(.video-component) {
 position: relative;
}
#course-page .course-components .video-component {
  width:100%;
  height:100%;
  position: relative;
}
#course-page .course-components.introduction-component, #course-page .course-components .quiz-component, #course-page .course-components .upk-component, #course-page .course-components .scorm-component, #course-page .course-components .assessment-component, #course-page .course-components .document-component {
  height:70vh;
  width:100%;
}
#course-page #firstLoadImageLink {
 background-repeat: no-repeat;
 background-size: cover;
 background-position: bottom left;
 background-color: rgb(229,216,179);
 position: relative;
}
#course-page #firstLoadImageLink .introTitleSection {
  position: absolute;
  top: 0;
}
#course-page #firstLoadImageLink .introMainSection{
  width: 100%;
}
#course-page #firstLoadImageLink .introMainSection > span{
  text-align: center;
}
@media only screen and (max-width: 600px) {
  #course-page #firstLoadImageLink .introTitleSection {
    position: relative;
  }
  #course-page #firstLoadImageLink.oj-sm-justify-content-center {
    justify-content: flex-start !important;
  }
}
@media only screen and (max-width: 372px) {
  #course-page #firstLoadImageLink #introCourseDetails {
    display: none;
  }
  #course-page #firstLoadImageLink .introMainSection{
    margin-top: 20px;
  }
}

#course-page #firstLoadImageLink h1 {
  max-width: 624px;
  font-size: 30px !important;
  font-weight: 400 !important;
  line-height: 45px !important;
}
@media only screen and (max-width: 1023.9px) {
  #course-page #firstLoadImageLink h1 {
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }
}
#course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-default,
#course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-hover,
#course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-disabled {
  height: 90px;
  width: 92px;
}
@media only screen and (max-width: 720px) {
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-default,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-hover,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-disabled {
    height: 70px !important;
    width: 72px !important;
  }
}
#course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-default.oj-button-cta-chrome .oj-button-button,
#course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-hover.oj-button-cta-chrome .oj-button-button,
#course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-disabled.oj-button-cta-chrome .oj-button-button {
  border-radius: 50%;
}


#course-page #firstLoadImageLink #goBack.oj-button {
  margin-left: -10px;
}
@media only screen and (max-width: 1023.9px) {
  #course-page #firstLoadImageLink #goBack.oj-button {
    margin-bottom: 16px !important;
  }
}
#course-page .courseDetailsSection ul.oj-listview-element.oj-component-initnode li {
  background-color: RGB(var(--oj-palette-neutoudl_videral-rgb-10)) !important;
}
#course-page .coursePlaying{
  display: inline-block;
  max-width: 650px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1470px) {
  #course-page .coursePlaying{
    max-width: 450px;
  }
}
@media only screen and (max-width: 1270px) {
  #course-page .coursePlaying{
    max-width: 400px;
  }
}
@media only screen and (max-width: 1190px) {
  #course-page .coursePlaying{
    max-width: 280px;
  }
}
@media only screen and (max-width: 1023.9px) {
  #course-page .coursePlaying{
    max-width: 100%;
  }
}
#course-page .w80 {
  min-width: 80vw;
}
#course-page .h80 {
  min-height: 80vh;
}
#course-page .video-js-component {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 6px;
}
#course-page #oudl_video_id {
  position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}
#course-page .video-js-component-container {
  height: inherit;
  width: inherit;
}
#course-page .oj-progress-circle.oj-progress-circle-sm {
  width: 0.9rem !important;
  height: 0.9rem !important;
}
#course-page .p3sdk-anchor-tags{
  color: black;
}
#course-page .p3sdk-interactive-transcript-content {
  text-align: justify;
  height: calc(80vh - 106px);
  overflow: auto;
  background-position: 0px -36px; 
  cursor: pointer;
  cursor: hand;
  padding: 10px 15px;
  overflow-y: auto;
  scrollbar-width: thin !important;
  scrollbar-color: #7a7a7a #f5f4f2 !important;
}
#course-page .topbar-background {
  padding: 0 15px;
}
@media only screen and (max-width: 1023.9px) {
  #course-page .p3sdk-interactive-transcript-content {
    padding: 10px 48px 0 0;
  }
  #course-page .topbar-background {
    padding: 0 48px 0 0;
  }
}
#course-page .p3sdk-interactive-transcript-content::-webkit-scrollbar {
  width: 8px;
}
#course-page .p3sdk-interactive-transcript-content::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 20px;
}
#course-page .p3sdk-interactive-transcript-content::-webkit-scrollbar-track {
  background: #f5f4f2;
}
#course-page .guidesList{
  overflow: auto;
}
#course-page .p3sdk-interactive-transcript-search-hit {
  background: #2D8078;
  color: white;
}
#course-page .p3sdk-current-word {
  color: #C04535;
}
#course-page .p3sdk-tracked-word:not(.p3sdk-interactive-transcript-search-hit) {
  color:  #707070;
}
#course-page .p3sdk-current-word.p3sdk-interactive-transcript-search-hit{
  color: #A33A2E;
  background: #B1E2E2;
}
#course-page .p3sdk-tracked-paragraph {
  color:  #707070;
}
.course-modal-body {
  min-height: 60vh;
}
#course-page button.oj-button-button:focus-visible, #course-page .compAvailable:focus-visible, #course-page #course-announcements-archived>div:focus-visible, #course-page #course-info-duration>div:focus-visible, #course-page .oj-choice-direction-row:focus-visible, #course-page .course-page-focus:focus-visible, #course-page #playerIdbtn>button:focus-within {
  outline: 1px solid #201E1C;
  outline-offset: 1px;
}
#course-page .NextBtn, #course-page .PreviousBtn {
  color: rgb(255, 255, 255) !important;
 }
#course-page .navigation .oj-button-icon-only {
  min-width: 2.2rem !important;
}
#course-page .navigation .oj-button {
  height: 2.2rem !important;
}
#course-page .navigation.Previous{
  cursor: pointer;
  position: absolute;
  z-index: 3;
  display: block;
  left: 10.83%;
  top: 40.67%;
}
#course-page .navigation.Next{
  cursor: pointer;
  position: absolute;
  z-index: 3;
  display: block;
  right: 10.83%;
  top: 40.67%;
}
#course-page .navigation.Previous .navigationBtn,  #course-page .navigation.Next .navigationBtn{
  background: rgba(22, 21, 19, 0.4) !important;
  color: rgb(255, 255, 255) !important;
  font-size: 1em;
  text-decoration: none;
  padding:7px;
}
#course-page .navigation.Previous .navigationBtn:hover,  #course-page .navigation.Next .navigationBtn:hover{
  background: rgba(22, 21, 19, 0.9) !important;
}
#course-page .navigation.Previous .navigationBtnBorder, #course-page .navigation.Next .navigationBtnBorder{
  border-radius: 100px;
}
#course-page .oj-popup-tail.oj-popup-tail-simple.oj-right.oj-top::after, #course-page .oj-popup-tail.oj-popup-tail-simple.oj-center.oj-top::after {
  border-bottom: calc(var(--oj-popup-tail-height) - 1px) solid rgba(22, 21, 19, 0.9) !important;
}
#course-page .bc-ff-bb {
  display: flex;
}
#course-page .bc-ff-bb-span  {
  display: flex;
}
#course-page .skip-ff-bb {
  align-self: center;
  font-size: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
}
#course-page .bc-ff-bb-span:hover {
  cursor: pointer;
}
#course-page .tooltipMinWidthFav {
  width:fit-content
}
#course-page #playlistCourseList {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#course-page #playlistCourseList a.playlist-component-href {
  color: inherit;
}
#course-page #courseFeedbackReminder .oj-message-body {
  padding: 0px;
}
#course-page #courseFeedbackReminder .oj-message-header {
  padding: 0px;
  min-height: 10px;
}
#course-page .course-feedback-reminder-body {
  padding: 0px 60px 16px 44px;
}
#course-page .course-feedback-reminder-header {
  padding: 0px 10px 0px 16px;
}
#course-page .course-feedback-reminder-title {
  padding-left: 8px;
}
#course-page .feedback-reminder-stars {
  max-height: 18px;
  max-width: 150px;
}
#course-page #video-loading-spinner, #course-page #playlist-spinner{
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .85;
  display: block;
  }
.returningUnauthorizedModalDialogHr {
  border-top: 1px solid #000!important;
  margin: 4vh 0 !important;
}
div > #returningUnauthorizedModalDialogCenter {
  width: 100%;
  text-align: center;
}
#returningUnauthorizedModalDialogCenter > oj-button, #returningUnauthorizedModalDialogCenter > a > oj-button {
  width: 50%;
}
#returningUnauthorizedModalDialog > div > div > h1 {
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
}
#course-page .student-guide-icon-img {
    height: 2.7rem;
}
#course-page .newLabel{
  font-family: Lato,"Helvetica Neue",HelveticaNeue,Helvetica,Arial,"Sans Serif";
  display: inline-block;
  font-size: 9px;
  font-weight: 600;
  padding: 2px 6px;
  border: solid 1px #b5b5b5;
  border-radius: 4px;
}
#assessmentModalDialog .leftAlignContent, #scormModalDialog .leftAlignContent {
  justify-content: flex-start !important;
}
#course-page .topics {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
#course-page .audience {
  overflow-wrap: normal;
  max-width: 350px;
}
#course-page .oj-listview-element li, #course-page .oj-listview-element li:hover,
#course-page oj-sp-expandable-list-item .oj-sp-expandable-list-item-header:hover {
    cursor: default;
}

#course-page #labCalenderListView .labCalendarHeader {
  font-size: 13px;
  font-weight: 700!important;
  color: #646464;
  text-align: center;
  padding: 10px 5px!important;
}

#course-page .calendar-container {
  max-width: 280px !important;
  width: 100% !important;
}
@media only screen and (max-width: 1023.9px) {
  #course-page .calendar-container {
    max-width: 360px !important;
  } 
}
@media only screen and (max-width: 369.9px) {
  #course-page .calendar-container {
    max-width: 280px !important;
  } 
}

#course-info-audience, #couse-info-topics, #couse-info-duration, #course-announcements-archived, #course-info-old-version {
  padding-left: 0 !important;
}

#introCourseDetails > div > div > span:first-child {
  display: inline-block;
  vertical-align: -5px;
  font-size: 20px !important;
}

#course-page .courseDetailsSection .courseTitleBadge {
  vertical-align: 3px;
}
#course-page .sidebar-slot .oj-listview-element.oj-component-initnode li.labCalendarHeaderLi {
  background: #e0dcd7 !important;
}

#course-page a.viewHideDescLink {
  color: var(--oj-link-text-color);
  display: inline-block;
}
#course-page a.viewHideDescLink:hover {
  color: var(--oj-link-text-color-hover);
  text-decoration: none;
}
#course-page #labDetails {
 overflow-y: auto;
 height: 85vh;
}
#course-page #labDetails::-webkit-scrollbar {
  width: 8px;
}
#course-page #labDetails::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 20px;
}
#course-page #labDetails::-webkit-scrollbar-track {
  background: #f5f4f2;
}

#course-page #labslotfilmStrip .oj-filmstrip-pages-container {
 background-color: RGB(var(--oj-palette-neutral-rgb-20)) !important;
}
#course-page #labslotfilmStrip .oj-filmstrip-pages-container ul {
  border: 1px solid var(--oj-core-divider-color) !important;
 }

#course-page .courseTopics .oj-flex-bar-end span {
  display: inline-block;
  width: 30px;
  text-align: right;
}

#course-page .lab-container {
  border: 0;
}

#course-page .moduleNumber {
  display: inline-block;
  vertical-align: top;
  padding-right: 1px;
}

#goBackTop {
  position: absolute;
  display: block;
  z-index: 3;
  top: 0px;
  left: 30px;
}

#goBackTop button.oj-button-button {
  background-color: "transparent";
  /* background-color: rgb(0 0 0 / 30%); */
  /* box-shadow: var(--oj-core-box-shadow-sm)!important; */
}
#goBackTop button.oj-button-button:hover {
  background-color: "transparent";
  /* background-color: rgb(40 40 40 / 30%);
  box-shadow: var(--oj-core-box-shadow-md)!important; */
}

.shortButtonLinks {
  margin-top: -4px;
}


/* For screens with 150% viewport */
@media only screen and (max-height: 630px) {
  .oj-tabbar-icon-only.oj-tabbar-vertical.oj-tabbar-vertical-end .oj-tabbar-item {
    padding-top: 4px !important;
    padding-bottom: 6px !important;
  }
  #course-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-content {
    padding: 4px 0px 4px 2px !important;
  }
}
@media only screen and (max-width: 1280px) and (max-height: 630px) {
  #course-page #firstLoadImageLink h1 {
    max-height: 90px;
    overflow: hidden;
    font-size: 22px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    margin-bottom: 8px !important;
  }
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-default,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-hover,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-disabled {
    height: 70px !important;
    width: 72px !important;
  }
  #introCourseDetails {
    font-size: 10px !important;
  }
  #introCourseDetails > div > div > span:first-child {
    font-size: 16px !important;
  }
  #course-page #firstLoadImageLink #goBack.oj-button {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .oj-tabbar-icon-only.oj-tabbar-vertical.oj-tabbar-vertical-end .oj-tabbar-item {
    padding-top: 4px !important;
    padding-bottom: 6px !important;
  }
  #course-page .courseDetailsSection > div {
    padding-top: 4px !important;
  }
  #course-page .courseDuration {
    height: 70px;
    width: 300px;
  }
  #course-page .sidebar-slot .playlistSection {
    height: calc(100% - 80px);
  }
  #course-page .oj-tabbar-vertical.oj-tabbar-icon-only .oj-tabbar-item-content {
    padding: 4px 0px 4px 2px !important;
  }
  #course-page .coursePlaying{
    max-width: 400px;
  }
  #course-page .nowPlayingBlock {
    max-width: 60% !important;
    width: 60% !important;
    flex: 0 1 60% !important;
  }
  #course-page .videoRatingBlock{
    max-width: 40% !important;
    width: 40% !important;
    flex: 0 1 40% !important;
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 1220px) and (max-height: 630px) {
  #course-page .coursePlaying{
    max-width: 330px;
  }
}
@media only screen and (max-width: 1180px) and (max-height: 630px) {
  #course-page .coursePlaying{
    max-width: 300px;
  }
}
@media only screen and (max-width: 1124px) and (max-height: 630px) {
  #course-page .coursePlaying{
    max-width: 280px;
  }
}

@media only screen and (max-width: 1154px) and (max-height: 630px) {
  #course-page .rateVideoComment {
    margin-top: -7px;
  }
}
@media only screen and (max-width: 1023.99px) and (max-height: 630px) {
  #course-page .rateVideoComment {
    margin-top: 0px;
  }
  #course-page .coursePlaying{
    max-width: 100%;
  }
  #course-page .nowPlayingBlock {
    max-width: 100% !important;
    width: 100% !important;
    flex: 0 1 100% !important;
  }
  #course-page .videoRatingBlock{
    max-width: 100% !important;
    width: 100% !important;
    flex: 0 1 100% !important;
    padding-left: 0px !important;
  }
  #course-page .nowPlayingBlock .coursePlaying{
    white-space: normal;
  }
}

@media only screen and (max-width: 830px) and (max-height: 630px) {
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-default,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-hover,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-disabled {
    height: 60px !important;
    width: 62px !important;
  }
}

@media only screen and (max-width: 710px) and (max-height: 630px) {
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-default,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-hover,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-disabled {
    height: 50px !important;
    width: 52px !important;
  }
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-default .oj-ux-ico-play,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-hover .oj-ux-ico-play,
  #course-page #firstLoadImageLink #playerIdbtn.oj-button.oj-disabled .oj-ux-ico-play {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 600px) and (max-height: 630px) {
  #course-page #firstLoadImageLink .introTitleSection {
    position: absolute !important;
  }
  #course-page #firstLoadImageLink.oj-sm-justify-content-center {
    justify-content: center !important;
  }
}

/* share */
.share-button-common.share-button-long .oj-button-label {
  display: flex;
  flex-direction: row-reverse;
}
.share-button-common.share-button-long .oj-button-label .oj-ux-ico-share.oj-button-icon.oj-end {
  margin-left: var(--oj-button-sm-icon-to-edge-padding);
}
.share-button-common.share-button-long .oj-button-label .oj-button-text {
  padding: 0 var(--oj-button-sm-text-to-edge-padding) 0 0;
}


/* Star flash ------------------------------------------------- */
.video-star-block {
  position: relative;
}
.icon-wrapper {
  text-align: center;
  margin-top: 0px;
  position: absolute;
  cursor: pointer;
  display: inline-block;
  top: 20px;
  left: 13px;
}
.icon-wrapper .satellite {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-left: -20px;
  margin-top: -20px;
}
.icon-wrapper .satellite span {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 0px;
  margin-left: 0px;
  transition: all ease 0.5s;
  transform-origin: center 0px;
  transform: translate(0, 0) scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
          animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation-iteration-count: 1;        
          animation-iteration-count: 1;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.icon-wrapper.anim .satellite span:nth-child(1) {
  top: 0;
  left: 50%;
  background: #988ade;
  -webkit-animation-name: satellite-top;
          animation-name: satellite-top;
}
.icon-wrapper.anim .satellite span:nth-child(2) {
  top: 25%;
  left: 100%;
  background: #de8aa0;
  -webkit-animation-name: satellite-top-right;
          animation-name: satellite-top-right;
}
.icon-wrapper.anim .satellite span:nth-child(3) {
  top: 75%;
  left: 100%;
  background: #8aaede;
  -webkit-animation-name: satellite-bottom-right;
          animation-name: satellite-bottom-right;
}
.icon-wrapper.anim .satellite span:nth-child(4) {
  top: 100%;
  left: 50%;
  background: #8adead;
  -webkit-animation-name: satellite-bottom;
          animation-name: satellite-bottom;
}
.icon-wrapper.anim .satellite span:nth-child(5) {
  top: 75%;
  left: 0;
  background: #ffa678;
  -webkit-animation-name: satellite-bottom-left;
          animation-name: satellite-bottom-left;
}
.icon-wrapper.anim .satellite span:nth-child(6) {
  top: 25%;
  left: 0;
  background: #8ad1de;
  -webkit-animation-name: satellite-top-left;
          animation-name: satellite-top-left;
}
/* loveleen testing */
.oj-button-icon-only :focus{
background:#eee;
}
/* loveleen testing */

@-webkit-keyframes satellite-top {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(0, -35px);
  }
}
@keyframes satellite-top {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(0, -35px);
  }
}
@-webkit-keyframes satellite-bottom {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(0, 35px);
  }
}
@keyframes satellite-bottom {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(0, 35px);
  }
}
@-webkit-keyframes satellite-top-right {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(32px, -16px);
  }
}
@keyframes satellite-top-right {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(32px, -16px);
  }
}
@-webkit-keyframes satellite-bottom-right {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(32px, 16px);
  }
}
@keyframes satellite-bottom-right {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(32px, 16px);
  }
}
@-webkit-keyframes satellite-bottom-left {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(-32px, 16px);
  }
}
@keyframes satellite-bottom-left {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(-32px, 16px);
  }
}
@-webkit-keyframes satellite-top-left {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(-32px, -16px);
  }
}
@keyframes satellite-top-left {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(0) translate(-32px, -16px);
  }
}