.oj-listview-item.oj-selected, .oj-listview-group-item.oj-selected {
  background-color: var(--oj-core-bg-color-selected) !important;
}
  
.oj-listview-item {
  border-bottom: none !important;
}
  
.ask-inst-form-block {
  margin-bottom: 10px;
}
  
.ask-inst-form-block span {
  color: #666;
  font-size: 12px;
  font-weight: 700;
  display: block;
}
  
.ask-instructor-drawer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 55vh;
  max-width: 60vh;
}
  
@media screen and (max-width:768px) {
  .ask-instructor-drawer {
    min-width: auto;
  }
}
  
.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-start;
}
  
.drawer-footer {
  margin-top: auto;
  text-align: right;
}
  
.oj-form-control-max-width {
  width: 100%;
}
  
.text-align-right {
  text-align: right;
}
  
.text-align-center {
  text-align: center;
}

.textRed {
  color: #f44336 !important;
}
