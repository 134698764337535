.widthcontrl{max-width: 320px;}

.filter-count {
    width: 100%;
}

.filter-count span {
    display: block;
    text-align: center;
    margin-right: 15%;
    margin-top: 1rem;
    font-weight: bold;
}