
.oj-sp-header-strip {
  background-image: var(--oj-sp-theme-page-header-strip, url('https://static.oracle.com/cdn/fnd/gallery/2401.0.1/images/color-strip-neutral.png'));
  background-position: left bottom;
  background-repeat: repeat-x;
  height: 12px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.oj-progress-bar-value{background-color: rgb(80, 130, 35) !important;}
.oj-ux-ico-circle-s, .oj-ux-ico-circle-half{color: rgb(80, 130, 35);}
.item-status{font-size:1.5em;}
.oj-accordion-collapsible > .oj-collapsible-header{font-weight: normal !important;padding:2em 0 !important;}
.oj-collapsible-header-icon{position: absolute;
  right: 0;
  font-size: 2em;}
.oj-collapsible-header > a:focus-within{
  outline: 2px dotted #201E1C;
  outline-offset: 2px;
}
.courseList{list-style: none;}.courseList li{margin-bottom: 1em;background: none;}
.oj-sp-typography-display-6 {
  font-family: Georgia, Times, "Times New Roman", serif;}
.sub-item-status{padding-right:0.60em;}
.moreBtn{width: 5em;padding:0 3em 0 0;}
.moreBtn .oj-collapsible-close-icon:before {
content: "\f105" !important;
}
.moreBtn .oj-collapsible-open-icon:before{
content: "\f105" !important;
transform: rotate(180deg);
}
.demo-offcanvas-panel-width {
width: 50%;
}
.not-selectable:hover {
  cursor: default;
}
.selectable:hover {
  cursor: pointer;
}
.oj-offcanvas-overlay{transform: translate3d(0,0,0) !important; background: #fff;}
.oj-offcanvas-outer-wrapper{overflow-y: scroll;}.oj-offcanvas-shift-start{overflow-y: hidden;}
.circleIcon{background:#6BA1B6;
color:#fff;
padding: 0.5em;
border-radius: 100%;
font-size: 1.4em;vertical-align: middle;margin-right: 0.5em;}
.oj-collapsible-header.stepHeader{display: block;}
.stepHeader h4,.stepHeader h3, .stepHeader p{margin-bottom: 0 !important;}.slotBtn{cursor: pointer;padding: 1em 0.5em;border-radius: 0;font-size: 1.1em; background: none;}
.slotBtn.selectedSlot{background: rgb(49, 45, 42); color:#fff}
.slotBtn.slot-disabled{cursor: not-allowed; opacity: 0.4;}
.selectedDatevalue.oj-disabled .oj-text-field-container{border:none;color:#000; background: transparent;}
.selectedDatevalue.oj-disabled .oj-inputdatetime-input-trigger{display: none;}
.selectedDatevalue.oj-disabled .oj-text-field-input{color:#000;padding:0;}
.stepStatus{position:absolute; right:10%; top:2em}#agreeTerms{width:1.5em; height:1.5em; margin-right: 1em; vertical-align: sub;}
.oj-badge.stepStatus{ top:2.5em}.oj-sm-pos-top{position: relative; top:0.5em}
.oj-tabbar-horizontal:not(.oj-tabbar-icon-only) .oj-tabbar-item-content{font-weight: bold;}
.card-icons .oj-flex-bar-middle{display: block;} .card-icons h4{margin-bottom: 0 !important;}
.card-icons svg{margin-top: 0.5em;}.demo-holiday span{
    background-color: rgb(var(--oj-palette-danger-rgb-40));
}
#slotfilmStrip .oj-filmstrip-pages-container{background: #fff;border-top: #ccc 1px solid;border-bottom: #ccc 1px solid;}
#slotfilmStrip .oj-panel{border: none;}
.timeLiteral{display: block;}.timeCounter>.oj-flex-item:last-child{display: none;}.timeCounter>.oj-flex-item{text-align: center;}
.oj-bg-success{background: #508223;color:#fff}.oj-text-warning{color:#AC630C}
.oj-text-success{color:#508223}.oj-bg-warning {background:#AC630C;color:#fff}
.oj-bg-info{background: #227E9E; color: #fff;}
.oj-icon-txt-big{font-size: 5em;}
.cert-badge-img {margin-top: -40px;}
@media only screen and (max-device-width :767px){
  .sm-display-block{display: block;}
  .oj-sp-header-strip{border-radius: 0;}
  ul{margin: 0 0.5em;}.demo-mypanel {margin-bottom: 1em;}
  .mobile-action-panel{
    position:fixed;
    bottom: 0;
    background: #fff;
    text-align: center;
    z-index: 5;
    width: -webkit-fill-available;
    left: 0;}
    .slotBtn{padding: 0.25em;}
    .oj-datepicker-content{padding-bottom:15em;}
}
@media only screen and (max-device-width : 1024px){
  .hideimg{display: none;}
  ul{padding:1em;}
  .creditName{margin: 0;}
  .cert-badge-img {margin: 20px auto; text-align: center;}
}
@media only screen and (min-device-width : 1025px){
  .demo-mypanel {margin: 0 1em 1em 0;}
}
@media only screen and (min-device-width : 768px){
.columns2 div{column-count: 2;margin-bottom: 2em;}
}
.columns2 ul, .columns2 li, .columns2 li:hover, li.oj-tabbar-item,li.oj-tabbar-item:hover{background: none;}
.bulletList li, .columns2 ul li, #userRegistred ul li, #userRegistred ul li:hover, #examc1 ul li, #examc1 ul li:hover {list-style: disc; background: none;}
.columns2 ul, ul.bulletList, #userRegistred ul, #examc1 ul {padding: 0 30px;}
.oj-conveyorbelt-item p{margin: 0;}
.oj-button#backBtn .oj-button-icon {margin-top: -8px;}
.oj-button#favorites .oj-button-icon, .oj-button#share .oj-button-icon {margin-top: 8px;}
#exam-page button.oj-button-button:focus, #exam-page a:focus { outline: 2px dotted #201E1C; outline-offset: 2px;}
#exam-page oj-tab-bar a:focus { outline: none; border: 2px dotted #201E1C;}
#exam-page .oj-web-applayout-max-width, #registerPage .oj-web-applayout-max-width {max-width: 1600px;}
#registerPage button.oj-button-button:focus, #registerPage a:focus { outline: 2px dotted #201E1C; outline-offset: 2px;}
#registerPage oj-tab-bar a:focus { outline: none; border: 2px dotted #201E1C;}
#registerPage oj-radioset span.oj-choice-item.oj-focus {  outline: 2px dotted #201E1C !important; outline-offset: 2px !important;}
#cancelExamModalDialog_layer button:focus { outline: 2px dotted #201E1C; outline-offset: 2px;}
.timeCounter > div {position: relative; min-width: 15px;}
.timeCounter > div > span {color: #100f0e87;}
.timeCounter > div > b {top: -21px; position: absolute;}
.pageLoader {
text-align: center;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
vertical-align: middle;
background-color: rgba(0, 0, 0, 0.3);
display: table;
}
#cancelModal {
transition: all .2s ease-in-out;
opacity: 0;
z-index: 999999;
}
#cancelModal #cancelDailog {
transition: all .2s ease-in-out;
transform: translate(0px, -100%);
opacity: 0;
}
#cancelModal.show {
opacity: 1;
}
#cancelModal.show #cancelDailog {
transform: translate(0px, 0%);
opacity: 1;
}

.pageLoader .middle {display: table-cell; vertical-align: middle;}
.pageLoader .middle #cancelDailog {position: relative !important; margin: 0 auto;}
.pageLoader .middle #cancelDailog .oj-dialog-content {text-align: left;}
.courseList .oj-sp-typography-display-6{font-size: 1rem !important;}
.table-sizing{width: 100%;}
input::selection {
color: inherit;
background: inherit;
}
.oj-table-column-header{font-weight: bold;} .examVouchers .oj-badge{margin: 0;}

.w80 {
min-width: 80vw;
}
.h80 {
min-height: 80vh;
}
td.creditDetailsTble.oj-table-data-cell:first-child{padding: 2.5em;}.creditDetailsTble table{margin-bottom:2em;}
.creditDetailsTble td, .creditDetailsTble th{padding: 1em 2em;text-align: left; border-bottom: #ccc 1px solid; font-size: 12px;}
.iconcircleAvatar .oj-avatar{width: auto;
margin: 0;height: auto;display: grid;vertical-align: middle;}.iconcircleAvatar {float: left;}
.creditName{margin-left: 1em;display: inline-block;}.summaryCredit{padding-left: 2em;}.summaryCredit strong{display: inline-block; margin-bottom: 0.5em;}
html:not([dir="rtl"])
.oj-rowexpander-expand-icon:before {
  content: "\f11f"; font-size: 1.3em;
}
.oj-fwk-icon-arrow03-s:before, .oj-rowexpander-collapse-icon:before {
  content: "\f121"; font-size: 1.3em;
}
#MyCreditstable .oj-table-data-cell{padding-top: 2em !important; padding-bottom: 2em !important;}
.bgWhite.oj-hover{background: #fff;box-shadow:none}
@media only screen and (max-device-width : 1024px){
  .creditName{margin: 0;}
}
a.logoSection:hover{color: inherit;}
.lsTile:hover{opacity: 0.95;}
.lsTile a{display: inline-block;}
.lsTile a:hover{text-decoration: none;color: inherit;}
.badge-black{background-color: #312d2a; color: white;}
.oj-message{padding: 16px;background-color: rgb(246, 250, 252);border-bottom: 1px solid var(--oj-core-divider-color);border-top: 1px solid var(--oj-core-divider-color);display: flex;
  align-items: center;font-weight: bold;color: rgb(0, 104, 140)}
/*
.oj-message .oj-message-status-icon{margin-right:0.5em; vertical-align: middle;color:rgb(0, 104, 140)}
*/
.exam-credit-expired,
.exam-credit-expired .oj-text-color-secondary{
  color: #757575 !important;
}
tr.exam-credit-expired td:first-child {
  display: flex;
  height: auto !important;
  align-items: center;
}

oj-button#backBtn:focus-within {
  outline: 2px dotted #201E1C;
  outline-offset: 2px;
}

oj-table#MyCreditstable{
  --oj-core-focus-border-color: #201E1C;
}

.exam-info-box {
  margin-top: -30px;
}

.icn-infocircle {
  background: #6BA1B6;
  border-radius: 100%;
  color: #fff;
  font-size: 1.9em;
  width: 50px;
  height: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.access-code-field {
  width: 100% !important;
  max-width: 440px !important;
}

.icn-infocircle.done {
  background: #508223;
}
.icn-infocircle.done span {
  font-family: ojuxIconFont!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  speak: none;
  vertical-align: -3px;
}

.icn-infocircle.done span:before {
  content: '\f1be';
}

.pbt-error-warning-icon {
  vertical-align: -3px;
  font-size: 90%;
  margin-right: 5px;
}

.pbt-error-warning-circle {
  background: #227E9E !important;
  border-radius: 100%;
  color: #fff;
  font-size: 2.6em;
  width: 60px;
  height: 60px;
  text-align: center;
  margin-top: 12px;
}

.pbt-error-warning-circle span {
  vertical-align: -4px;
}

.timeLiteral {
  margin-bottom: 5px;
}

.timeLiteral > span {
  margin-bottom: 15px;
  display: block;
}

@media only screen and (max-width: 599px){

  #exam-conveyor-belt {
    width: 80vw;
  }

  .exam-info-box {
    margin-top: 0px;
  }

  .icn-infocircle {
    font-size: 1.4em;
    width: 40px;
    height: 40px;
  }

  .icn-infocircle-parent {
    margin-bottom: 10px;
  }

  .pbt-error-warning-circle {
    margin-top: auto;
  }

  .timeLiteral, .timeLiteral > span {
    margin-bottom: 0;
  }

  .timeLiteral {
    display: flex;
    align-items: center;
  }

}
#exam-page a {
    color: var(--oj-link-text-color) !important;
}


/* private event code component */
.private-code-message {
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.private-code-message .private-code-message-status-icon {
  margin-right: 0.5em;
  vertical-align: middle;
  color: rgb(179 49 31);
}


.private-code-message-detail {
  color: var(--oj-user-assistance-inline-text-color);
  font-size: var(--oj-private-message-detail-font-size);
  line-height: var(--oj-private-message-detail-line-height);
  word-break: break-word;
  width: 100%;
}
.private-code-success-message {
  vertical-align: middle;
  color: green;
}

.resume-pbt-exam {
  display: block;
  margin-bottom: 5px;
}

.resume-pbt-exam > span {
  display: block;
  margin-bottom: 0;
}

#commonDialog button.oj-button-button:focus-visible {
  outline: 2px dotted #201E1C;
  outline-offset: 1px;
}

.pbt-score-pass .oj-progress-bar-value{background-color: rgb(80, 130, 35) !important;}
.pbt-score-fail .oj-progress-bar-value{background-color: rgb(214 59 37) !important;}


#buyExamCredits > button:active {
  outline: dotted 2px black;
  outline-offset: 2px;
}

#buyExamCredits > button:hover {
  outline: dotted 2px black;
  outline-offset: 2px;
}

#buyExamCredits > button:focus {
  outline: dotted 2px black;
  outline-offset: 2px;
}

.asx-proctorio-list-icon .oj-avatar {
  background-color: #6BA1B6;
}
.asx-proctorio-list-icon .oj-avatar-background  {
  background-image: none;
}
.asx-proctorio-list-icon .oj-avatar .oj-avatar-icon {
  font-size: 1.4em;
}
.asx-proctorio-list .oj-listview-element li {
  cursor: auto !important;
}


.exam-items-list-container .oj-accordion-collapsible > .oj-collapsible-header-wrapper {
  padding: 2em 0 !important;
}

.exam-items-list-container .oj-accordion-collapsible > .oj-collapsible-header-wrapper > .oj-collapsible-header {
  font-weight: normal !important;
  font-size: 14px !important;
}