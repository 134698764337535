.activation-wrapper {
    width: 100%;
    min-height: 600px;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.activation-wrapper .oracle-icon i {
  font-size: 4em;
}

.activation-wrapper .oracle-icon i {
    font-size: 7em;
}

.activation-wrapper .tenant-banner {
    margin-left: 20%;
    margin-top: 10%;
    margin-bottom: 2%;
}

.activation-wrapper .tenant-banner img {
    max-width: 200px;
}

.activation-wrapper .activation-content {
    justify-content: space-between;
    flex-direction: column;
    max-width: 50vw;
    align-items: flex-start;
    min-height: 60vh;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 2em;
    backdrop-filter: blur(3px);
}

.activation-wrapper .activation-content .welcome-content {
  width: 100%;
}

.activation-wrapper .activation-content .features-benefits {
    margin-right: 20%;
    width: 25%;
} 

.activation-wrapper .activation-content .features-benefits h3 {
    font-size: 1rem;
    font-weight: 800;
}

.activation-wrapper .activation-content .features-benefits p {
    font-size: 0.9rem;
    font-weight: 400;
}

.activation-wrapper .activation-content .features-benefits div {
    margin-top: 20px;
}

.activation-wrapper .activation-content .welcome-content h2 {
    font-size: 2.5em;
    font-weight: 800;
}

.activation-wrapper .activation-content .welcome-content p {
    font-size: 1.3em;
    line-height: 1;
}

.activation-wrapper .activation-content .welcome-content .get-started-button {
    width: 70%;
}

.activation-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5%;
  justify-content: center;
  margin-bottom: 10%;
}

.activation-buttons oj-button {
  margin-right: 1em;
}

.pending-reservation-name {
  
}

.pending-reservation-name p {
  margin: 0;
}

.activation-welcome-header {
  width: 100%;
}

.activation-footer {
  width: 100%;
  border-top: 2px solid rgb(244,243,240);
}

.activation-footer a {
  color: gray;
}

.activation-footer .activation-misc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.oj-button-text.loading-progress-circle {
  --oj-progress-circle-value-bg-color: var(--oj-button-call-to-action-chrome-text-color);
}

@media (max-width: 480px) {
    .activation-wrapper {
        min-height: 800px;
    }

    .activation-wrapper .oracle-icon {
        margin-left: 10%;
        margin-top: 20%;
    }

    .activation-wrapper .activation-content {
        margin-left: 10%;
        justify-content: space-between;
    }

    .activation-buttons {
      margin-right: 10%;
      justify-content: space-between;
    }

    .activation-wrapper .oracle-icon i {
        font-size: 7em;
    }

    .activation-wrapper .activation-content .welcome-content {
        width: 90%;
    }    

}

@media (max-width: 1024px) {
    .activation-wrapper {
        min-height: 1024px;
    }

    .activation-wrapper .activation-content .welcome-content .get-started-button {
        width: 100%;
    }

    .activation-buttons oj-button {
      margin-right: 0;
      width: 100%;
    }
}
