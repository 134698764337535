.skill-up-content h2 {
    margin: 0;
    font-size: 2em;
    font-family: "Oracle Sans";
    font-weight: bold;
    color: #0F0E0D;
}

.image-wrapper {
    width: 20%;
}

.image-wrapper img {
    width: 70%;
}

.filter-chips-wrapper {
    width: 50%;
}

@media (max-width: 768px) {
    .skill-up-content h2 {
        font-size: 1.5em;
        line-height: 2em;
    }
}

@media (max-width: 1024px) {
    .skill-up-content h2 {
        font-size: 1.75em;
        line-height: 2.25em;
    }
}