.demo-list {
    width: 100%;
    height: calc(100vh - 9.375rem);
    min-height: 18.75rem;
  }

  .demo-card {
    width: 19.5rem;
    height: 12.5rem;
  }
  
  .demo-tweet {
    overflow: hidden;
  }
  
  .demo-tweet span {
    word-wrap: break-word;
  }
  
  .demo-tweet a:focus {
    outline-offset: -2px;
    outline-style: solid;
  }

  .oj-flex .flex-header {
    padding-top: 10px;
    padding-left: 20px;
    background-color: rgb(49 45 42);
    margin-bottom: 10px;    
  }

  .oj-flex .flex-header .hf-field {
    padding-bottom: 10px;
  }

  .oj-flex .ht-field{
      padding: 10px;
  }

  .loadingContainer {
    padding-left: 40%;
    padding-top: 20%;
  }
  