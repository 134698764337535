.review-preference {
    width: 100%;
    min-height: 600px;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
}

.review-preference .review-content {
    flex-direction: column;
    width: 40%;
    margin-top: 70px;
    margin-left: 25%;
    margin-right: 20%;
}

.review-preference .review-content h2 {
    font-size: 2em;
    font-weight: 800;
}

.review-preference .review-content span {
    font-size: 1rem;
}

.review-preference .review-content .preferences {
    margin-top: 20px;
}

.review-preference .review-content .learning-start button.start-learning {
    width: 30%;
}

.review-preference .review-content .learning-start button.back-button {
    width: 20%;
}

@media (max-width: 480px) {
    .review-preference {
        min-height: 800px;
    }
}

@media (max-width: 1024px) {
    .review-preference {
        min-height: 1024px;
    }

    .review-preference .review-content {
        margin-left: 10%;
        margin-right: 10%;
    }

    .review-preference .review-content {
        width: 80%;
    }

    .review-preference .review-content .learning-start button.back-button {
        width: 100%;
    }

    .review-preference .review-content .learning-start button.start-learning {
        width: 100%;
    }
}
