.story-header-section-container .story-header-section-row {
  display: flex;
  flex-wrap: nowrap;
}

.story-header-section-custom .story-header-section-left-column {
  display: flex;
  flex-direction: column;
  white-space: normal;
}

.story-header-section-custom .story-header-width-50 {
  width: 50%;
}

.story-header-section-container .story-header-resources-section {
  margin-top: auto;
}

.story-header-section-container .story-header-resources-section table td:not(:empty) {
  padding-right: 4em;
  padding-bottom: 0.5em;
}

.story-header-section-container .story-header-resources-section table tr td:last-child {
  padding-right: 0;
}

.story-header-section-container .story-header-section-middle-column {
  margin-left: auto;
  align-self: flex-end;
}

.story-header-section-container .story-header-section-middle-column img {
  max-height: 400px;
}

.story-header-section-container .story-header-section-right-column img {
  max-width: 200px;
}

.story-header-section-container .story-header-resources-section a:focus {
  outline: dotted 1px var(--oj-core-focus-border-color);
  outline-offset: 1px;
}

#videoPopup .video-popup-header oj-button:focus-within {
  outline: dotted 1px #201E1C;
  outline-offset: 1px;
}

#videoPopup .video-popup-header {
  align-self: flex-end;
}

#videoPopup .video-popup-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#videoPopup .video-popup-content video-js {
  width: 1080px;
  height: 607px;
}

.story-header-section-container .story-header-section-right-column {
  float: right;
  text-align: right;
  display: flex;
  flex-wrap: nowrap;
}

.story-header-section-container .story-header-section-right-column-logo {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-left: 1em;
}

.story-header-section-container .story-header-section-right-column-default {
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: start;
  margin-left: 8em;
}

.story-header-section-container .story-header-section-right-column oj-button {
  display: inline-block;
}

.story-header-section-container .community-dialog-wrapper {
  display: inline-block;
}

.story-header-section-container .story-header-section-row .story-header-section-left-column div.oj-header-description {
  margin-top: 1rem;
}

@media screen and (max-width:1440px) {
  #videoPopup .video-popup-content video-js {
    height: 495px; 
    width: 880px;
  }

  .story-header-section-container .story-header-section-right-column-default {
    margin-left: 0;
  }

  .story-header-section-container .story-header-section-right-column {
    float: left;
    margin-left: 0;
  }

  .story-header-section-custom .story-header-section-middle-column {
    margin-left: 0;
  }
  
  .story-header-section-custom .story-header-section-right-column {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .story-header-section-custom .story-header-section-middle-column img {
    max-height: 300px;
  }

  .story-header-section-custom .story-header-section-right-column img {
    max-width: 150px;
  }

  .story-header-section-custom .story-header-resources-section table {
    width: 100%;
    margin-right: 0;
  }

  .story-header-section-custom .story-header-resources-section table td {
    display: inline-block;
  }

  .story-header-section-custom .story-header-resources-section table td:not(:empty) {
    padding-right: 20px;
  }
}

@media screen and (max-width:970px) {
  #videoPopup .video-popup-content video-js {
    width: 680px;
    height: 383px;
  }

  .story-header-section-custom .story-header-section-with-video {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .story-header-section-custom .story-header-section-left-column {
    width: auto;
  }

  .story-header-section-custom .story-header-section-right-column {
    margin-top: 0px;
    gap: 8px;
  }

  .story-header-section-custom .story-header-section-middle-column img {
    max-height: 250px;
  }

  .story-header-section-custom .story-header-section-right-column img {
    max-width: 100px;
  }

  .story-header-section-custom .story-header-section-with-video {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .story-header-section-custom .story-header-section-left-column {
    width: auto;
  }

  .story-header-section-custom .story-header-section-right-column {
    margin-top: 0px;
    gap: 8px;
  }

  .story-header-section-custom .story-header-section-middle-column {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  .story-header-section-custom .story-header-section-right-column-logo {
    margin-left: 0em;
    margin-bottom: 1em;
  }

  .story-header-section-custom .story-header-section-middle-column img {
    max-height: 250px;
  }

  .story-header-section-custom .story-header-section-right-column {
    display: flex;
    align-items: center;
    width: auto;
    gap: 0;
  }
}

@media screen and (max-width:780px) {
  .story-header-section-container .story-header-section-row {
    flex-direction: column;
  }

  #videoPopup .video-popup-content video-js {
    width: 540px;
    height: 303px;
  }
}

@media screen and (max-width:640px) {
  #videoPopup .video-popup-content video-js {
    width: 340px;
    height: 191px;
  }
}

@media screen and (max-width:450px) {
  #videoPopup .video-popup-content video-js {
    width: 280px;
    height: 158px;
  }

  .story-header-section-custom .story-header-section-left-column {
    width: 100%;
  }

  .story-header-section-custom .story-header-section-middle-column {
    margin-bottom: 1em;
  }

  .story-header-section-container .story-header-section-right-column-default {
    flex-direction: column;
  }
}

@media screen and (max-width:300px) {
  #videoPopup .video-popup-content video-js {
    width: 180px;
    height: 101px;
  }
}