.onboarding-wrapper {
    width: 100%;
    min-height: 600px;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
}

.onboarding-wrapper .oracle-icon {
    margin-left: 20%;
    margin-top: 5%;
}

.onboarding-wrapper .oracle-icon i {
    font-size: 7em;
}

.onboarding-wrapper .tenant-banner {
    margin-left: 20%;
    margin-top: 10%;
    margin-bottom: 2%;
}

.onboarding-wrapper .tenant-banner img {
    max-width: 200px;
}

.onboarding-wrapper .onboarding-content {
    margin-left: 20%;
    justify-content: space-between;
}

.onboarding-wrapper .onboarding-content .welcome-content {
    width: 35%;
}

.onboarding-wrapper .onboarding-content .features-benefits {
    margin-right: 20%;
    width: 25%;
} 

.onboarding-wrapper .onboarding-content .features-benefits h3 {
    font-size: 1rem;
    font-weight: 800;
}

.onboarding-wrapper .onboarding-content .features-benefits p {
    font-size: 0.9rem;
    font-weight: 400;
}

.onboarding-wrapper .onboarding-content .features-benefits div {
    margin-top: 20px;
}

.onboarding-wrapper .onboarding-content .welcome-content h2 {
    font-size: 2.5em;
    font-weight: 800;
}

.onboarding-wrapper .onboarding-content .welcome-content p {
    font-size: 1.3em;
    line-height: 1;
}

.onboarding-wrapper .onboarding-content .welcome-content .get-started-button {
    width: 70%;
}

@media (max-width: 480px) {
    .onboarding-wrapper {
        min-height: 800px;
    }

    .onboarding-wrapper .oracle-icon {
        margin-left: 10%;
        margin-top: 20%;
    }

    .onboarding-wrapper .onboarding-content {
        margin-left: 10%;
        justify-content: space-between;
    }

    .onboarding-wrapper .oracle-icon i {
        font-size: 7em;
    }

    .onboarding-wrapper .onboarding-content .welcome-content {
        width: 90%;
    }    

}

@media (max-width: 1024px) {
    .onboarding-wrapper {
        min-height: 1024px;
    }

    .onboarding-wrapper .onboarding-content .welcome-content .get-started-button {
        width: 100%;
    }
}
