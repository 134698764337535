#digitalLibrary li a {
  color: black;
}

#columnWrapper > div > ul > li > a:focus {
  text-decoration: underline;
  outline: none;
  color: initial;
}

#columnWrapper > div > ul > li > a:hover {
    text-decoration: underline;
    outline: none;
    color: initial;
}

#digitalLibrary li:hover {
  background-color: transparent;
}

#digitalLibrary li {
  list-style: none;
  background-color: transparent;
  padding: 0.3em 0;
  line-height: 1.4;
}

#digitalLibrary ul {
  padding: 0 0 0 20px;
  margin-bottom: 30px;
}

.productCategory {
  break-inside: avoid;
}

.columnWrapper4Col {
  -webkit-columns: 4 300px;
  -moz-columns: 4 300px;
  columns: 4 300px;
}

.columnWrapper3Col {
  -webkit-columns: 3 300px;
  -moz-columns: 3 300px;
  columns: 3 300px;
}
