.emptyContainer {
  height: 80vh;
}
.pageLoader {
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.3);
	display: table;
    z-index: 999999;
}
.pageLoader .middle {
	display: table-cell;
	vertical-align: middle;
}
.oj-ux-ico-oracle-o.animate {
    font-size: 10rem;
    left: 50%;
    margin-left:-80px;
    position: absolute;
    top: 40%;
    z-index: 20;
    -webkit-animation: loaderLoop 0.7s ease-in-out infinite;
    -moz-animation: loaderLoop 0.7s ease-in-out infinite;
    animation: loaderLoop 0.7s ease-in-out infinite;
}

@-webkit-keyframes loaderLoop{
    0%{color:rgba(255, 255, 255, 0.1);}
    100%{color:#211e1c;}
}

@-moz-keyframes loaderLoop{
    0%{color:rgba(255, 255, 255, 0.1);}
    100%{color:#211e1c;}
}

@keyframes loaderLoop{
    0%{color:rgba(255, 255, 255, 0.1);}
    100%{color:#211e1c;}
}