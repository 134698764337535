.refine-section-container {
    margin-top: 1em;
}

#filtersection{background-color: #fafafa;}

.showMoreBtn {
    font-weight: 400;
    height: auto;
    margin-bottom: 0;
}

@media (max-width: 1024px) {
    .refine-section-container {
        margin-top: 0;
        background: whitesmoke;
    }

    .refine-section-container .refine-section-sm-header {
        display: flex;
        flex-direction: row;
        height: 60px;
        width: 100%;
        background: #f1efed;
    }

    .refine-section-container .back-button {
        height: 100%;
    }

    .refine-section-container .back-button button {
        height: 100%;
        background: transparent;
        border: none;
        margin-left: 10px;
    }

    .refine-section-container .back-button button:focus {
        outline: 2px dotted #3f3a36;
        outline-offset: 2px;
    }

    .refine-section-container .back-button button svg {
        color: black;
    }

    .refine-section-container .filter-count {
        width: 100%;
    }

    .refine-section-container .filter-count span {
        display: block;
        text-align: center;
        margin-right: 15%;
        margin-top: 1rem;
        font-weight: bold;
    }

    .refine-section-container .refine-section-sm-cta {
        position: sticky;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: white
    }

    .refine-section-container .refine-section-sm-cta button {
        width: 95%;
        height: 40px;
        margin: 10px 10px 10px 10px ;
        border-radius: 5px;
    }

    .refine-section-container .refine-section-sm-cta button:first-of-type {
        border: 1px solid;
        font-weight: bold;
    }

    .refine-section-container .refine-section-sm-cta #resetFilters button:focus {
        outline: 2px dotted #201E1C;
        outline-offset: 2px;
    }

    .refine-section-container .refine-section-sm-cta button:last-of-type {
        color: white;
        background-color: #312d2a;
    }


    .refine-section-container .refine-section-sm-cta #resetFilters button:focus {
        outline: 2px dotted #201E1C;
        outline-offset: 2px;
    }


    
    .refine-section-container .refine-section-sm-cta #applyFilters button:focus {
        outline: 2px dotted #201E1C;
        outline-offset: 2px;
    }
}

.search_ref_filters {
    border-bottom: #ccc 1px solid;
}

.showLessActive > .oj-checkboxset-wrapper > span.oj-choice-item:nth-child(n+10) {
    display: none;
}

.search_ref_filters .oj-radio-label, .oj-checkbox-label {
    font-size: var(--oj-typography-body-sm-font-size);
}

.search_ref_filters a:focus {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}

/* Style used for <oj-option> checkboxes in refine section */
/* TODO: Delete this style once OJET component focus issue is fixed */
.search_ref_filters .oj-checkboxset-wrapper .oj-focus {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}