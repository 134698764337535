.search-header-tab-container {
  overflow-x: auto;
}
.search-header-tab-filter-button-container {
  width: 275px;
}

.search-tab-navigation-container {
  gap: 50px;
}
.search-tab-tab-container {
  height: 100%;
  width: 300px;
  cursor: pointer;
  border-style: solid;
  border-width: 0 0 4px 0;
  border-color: rgb(var(--oj-palette-neutral-rgb-50));
}
.search-tab-selected-tab {
  border-color: rgb(var(--oj-palette-success-rgb-110));
}
.video-search-tab-badge {
  border-radius: 0.375rem;
  height: 1.5rem;
  font-size: 0.6875rem;
  font-weight: 700;
  font-stretch: condensed;
}