.hide{display:none}
.search-res-cont{
    border: 1px dotted green;
    margin-top: 10px;
    border-radius: 5px;
}

.searchresult-section{
    padding-left: 45px;
    padding-top: 25px;
}

#main-slot-wrapper a:focus {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}

.refine-section {
    padding-top: 25px;
    padding-left: 15px;
    min-height: 500px;
    pointer-events: auto;
    display: inline;
    border-style: solid;
    background-color: rgb(255, 255, 255);
    border-width: 0px;
    overflow: visible;
    border-color: rgb(38, 34, 33);
    z-index: 0;
    box-shadow: rgba(155, 155, 155, 0.62) 3px 2px 7px;
}

.refine-section-container oj-collapsible {
    margin-bottom: 15px;
}

.search_res_sec {
    margin-bottom: 20px;
}

.sr_resFor {
    font-size: large;
}

.se-displayIcons {
    display: flex;
}

.se-displayIcons button {
    border: 1px solid;
    border-radius: 2px;
}

.se-displayIcons button:last-child {
    border-left: 0px;
}

.se-displayIcons button.active {
    background-color: white;
    border: 1px solid;
    border-radius: 2px;
}

.se-displayIcons button.active:last-child {
    border-left: 0px;
}

.se-displayIcons i {
    font-size: large !important;    
}

@media (max-width: 768px) {
    .se-summ-head {
        margin-top: 10px;
    }

    .se-summ-head > h2 {
        padding-left: 3rem;
        font-size: 10;
    }
}

@media screen and (max-width: 320){
    .empty {
      font-size: 14px;
    }
}

.se-summ-head h2{
   margin-bottom: 0;
}

.search_ref_header{
    margin-bottom: 20px;
}

.search_ref_filters{
    margin-bottom: 1px;
}

.search_ref_filter_buton{
    pointer-events: auto;
    position: absolute; z-index: 36; 
    border-style: solid; 
    padding: 8px 8px 8px 34px; 
    border-width: 1px; 
    user-select: none; 
    transform-origin: 50% 50%; 
    border-radius: 4px; 
    border-color: rgba(33, 30, 28, 0.31); 
    line-height: 12px; 
    display: inline; 
    overflow-wrap: break-word; 
    font-family: "Noto Sans"; 
    font-size: 14px; 
    text-align: left; 
    font-weight: normal; 
    color: rgb(16, 15, 0); 
    cursor: pointer; 
    overflow: visible; 
    width: 157px; 
    top: 13px; 
    left: 9px;
}
.se-summ-head h2 {
    margin-bottom: 0;
    font-size: 1.6em;
    font-weight: bold;
}
.oj-listview-card-group li, .oj-listview-card-group li:hover{background: none;}
oj-sp-smart-filter-search .oj-sp-smart-filter-dashboard-inner-wrapper > * {
    margin: 0;
}

samp{font-weight: bold;}
#filtersection .ant-checkbox-group, #filtersectionv .ant-collapse-borderless{
    background:#fdfcfc;
}
.ant-collapse-header-text{display: block;}
#filtersection .ant-collapse-borderless{padding:1em 2em}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{padding:12px 0}
#filtersection .ant-collapse-content > .ant-collapse-content-box{padding:0 0 16px 0;}
#filtersection > .search_ref_filters:first-child .ant-collapse-borderless{
padding-top: 0;
}
oj-sp-smart-filter-search .oj-sp-smart-filter-dashboard-wrapper {
    max-width: 275px;
}
.buttonpanel{
    position: sticky;
    bottom: 0;
    width:99%;
    background: #fff;
    text-align: center;
    padding:1em;
}
.buttonpanel oj-button{width:100%}
.overflowText{display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;}
.oj-navigationlist-toolbar, .filterPanel{
    background:#f1efed
}
#oj-sp-card-footer-1_more1 button:focus-within:focus-within {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
    transform: scale(1.02);
}
#oj-sp-card-footer-1_more1 + oj-button.oj-enabled > button:focus-within {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}
.oj-pagingcontrol-nav-next:focus-within{
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}
.oj-pagingcontrol-nav-last:focus-within{
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}
.oj-pagingcontrol-nav-page > span:focus-within {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}
.oj-pagingcontrol-nav-previous:focus-within {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}
.oj-pagingcontrol-nav-first:focus-within {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}
.oj-navigationlist-current-header{
    text-align: left;
    padding-left: 2em;
}
.ant-checkbox-wrapper{color: rgb(22, 21, 19);margin-bottom: 5px;display: flex;
    align-items: center;}
@media only screen and (max-width:1024px) {
.se-summ-head h2 {font-size: 1.3em;}
}


.oj-inputtext-input{
    background-color: transparent;
    border: none;
}

.oj-scrollbar-force::-webkit-scrollbar, body::-webkit-scrollbar{
    background-color: #fff;
}

.oj-scrollbar-force::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb{
    background-color: #a9a9a9;
    /*var(--oj-scrollbar-thumb-color-hover);*/
}

.oj-scrollbar-force::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
    background-color: #fff;
}

:hover::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
}

:hover::-webkit-scrollbar-track {
    background-color: #fff;
}

.empty-search-results {
    height: calc(100vh - 16em);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 5vh;
}

.empty-search-results ul li {
    background: none;
}

@media (min-width: 768px) {

    .empty-search-results ul {
        margin-left: 5rem;
        margin-top: 1rem;
    }
}

@media (max-width: 767px) {
    .empty-search-results-container {
        margin-left: 1rem;
    }
}
.search-box oj-sp-filter-chip .oj-sp-filter-chip-applied:not(.oj-disabled).oj-active .oj-sp-filter-chip-chrome {
    background-color: rgb(34, 126, 158);
    border-color: rgb(34, 126, 158);
    margin:0.5em 0 0 1em;
}
.oj-button-toggle.oj-selected.oj-button-outlined-chrome{opacity: 0.5;color:#666}

#oj_sfs1_h_smartfilters .oj-choice-item.oj-selected:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
}

#main-slot-wrapper-child ul li h1 {
    font-size: 18px;
    margin-bottom: 1em;
}