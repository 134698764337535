#userProfile > oj-option > a:hover {
    text-decoration: underline;
}

#userProfile > oj-option > a:focus {
    text-decoration: underline;
}

#userProfile > oj-option > a:active {
    text-decoration: underline;
}
#userProfile > oj-option > a > a:hover,
#userProfile > oj-option > a > a:focus,
#userProfile > oj-option > a > a:active {
    text-decoration: underline !important;
}
