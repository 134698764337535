.view-button-set {
  border: 1px solid var(--oj-button-outlined-chrome-border-color);
}
.view-button-set-first {
  border-top-left-radius: var(--oj-button-border-radius);
  border-bottom-left-radius: var(--oj-button-border-radius);
}

.view-button-set-last {
  border-left: 0;
  border-top-right-radius: var(--oj-button-border-radius);
  border-bottom-right-radius: var(--oj-button-border-radius);
}

.view-button-set-not-pressed {
  background-color: rgba(255, 255, 255, .12);
}

span.view-button-set-icon {
  margin-left: 8px;
  color: var(--oj-core-text-color-secondary);
}
.view-button-set-not-pressed:hover span.view-button-set-icon {
  color: var(--oj-core-text-color-primary);
} 

.view-button-set:not(.view-button-set-not-pressed) > .oj-button-button {
  cursor: default;
}
.view-button-highlighted {
  outline: dotted 1px var(--oj-core-focus-border-color);
  outline-offset: 1px;
}