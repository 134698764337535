oj-button button:disabled {
    color: var(--oj-button-outlined-chrome-text-color-disabled) !important;
    border-color: var(--oj-button-outlined-chrome-border-color-disabled) !important;
    background-color: var(--oj-button-outlined-chrome-bg-color-disabled) !important;
    cursor: default;
}

.oj-choice-item:focus-within {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}

oj-button[id*='cancelBtn']:focus-within, oj-button#saveBtn:focus-within, oj-button#skipBtn:focus-within, oj-button#nextBtn:focus-within, oj-button[id*='endButtonCloserAskTheInstructorDrawer']:focus-within, oj-button[id*='endButtonCloser']:focus-within {
    outline: 2px dotted #201E1C;
    outline-offset: 2px;
}
