.story-content-section-container {
  padding: 1em;
}

.story-content-section-row {
  display: flex;
}

.story-header-section-left-column{
  white-space: normal;
}

.story-content-section-left-column {
  width: 20em;

  background-color: gray;
}
  
.story-content-section-right-column {
  width: 100%;
  background-color: gainsboro;
}

.available-learning {
  margin-top: 1rem;
  margin-left: 1rem;
}

.story_ref_filters{
  margin-bottom: 1px;
}

.se-displayIcons {
  display: flex;
}

.se-displayIcons button {
  background-color: #f1efed;
  border: 1px solid;
  border-radius: 2px;
}

.se-displayIcons button:last-child {
  border-left: 0px;
}

.se-displayIcons button.active {
  background-color: white;
  border: 1px solid;
  border-radius: 2px;
}

.se-displayIcons button.active:last-child {
    border-left: 0px;
}

.loading-carousel {
  filter: blur(2px);
}

.se-displayIcons i {
    font-size: large !important;    
}

.story_ref_filters .oj-ux-ico-plus, .story_ref_filters .oj-ux-ico-minus {
  font-size: 12px;

}
#storypage .oj-sp-public-primary-content-container {
  background-color:#fff;
}

.storyHeader .oj-progress-bar-value {
  background: #ccc !important;
}
