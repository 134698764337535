:root {
    --backgroundDark: rgb(48,44,41);
    --fontColor: whitesmoke;
    --secFontColor: #abce93;
}

body{
    margin: 0px !important;
}
@media not all and (min-resolution:.001dpcm){ 
    @supports (-webkit-appearance:none) {
        .oj-line-clamp-safari {
            text-overflow: ellipsis;
        }
    }
}