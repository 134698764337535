.hideElementsSelect :nth-child(1) {
    display: none;
}

.hideElementsSelect {
    height: 4em;
}
html:not([dir="rtl"]) .oj-listbox-results-depth-1 > .oj-listbox-result-label{padding-left: 0;}
.globalNotification{min-width: 25em;position: fixed;
    right: 0.1em; z-index: 1024; border-radius: 10px;}
.globalNotification .oj-message-container{border-radius: 6px;}
.globalNotification .oj-message-body { position: relative; top:-0.5em;}
.globalNotification .oj-message-detail{font-size: 1.1em;}